import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import * as SidebarAdmin from "./SidebarAdmin";
import * as SidebarEmployee from "./SidebarEmployee";
import "./Navbar.css";
import { IconContext } from "react-icons";
import useAuth from "../hooks/useAuth";

function Navbar() {
  const { signout, user } = useAuth();
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  const navigate = useNavigate();
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="navbar">
          <Link to="#" className="menu-bars">
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {user.userType == "admin"
              ? SidebarAdmin.SidebarData.map((item, index) => {
                  return (
                    <li key={index} className={item.cName}>
                      <Link
                        to={item.path}
                        onClick={() => (document.title = item.title)}
                      >
                        {item.icon}
                        <span>{item.title}</span>
                      </Link>
                    </li>
                  );
                })
              : SidebarEmployee.SidebarData.map((item, index) => {
                  return (
                    <li key={index} className={item.cName}>
                      <Link
                        to={item.path}
                        onClick={() => (document.title = item.title)}
                      >
                        {item.icon}
                        <span>{item.title}</span>
                      </Link>
                    </li>
                  );
                })}
            <li>
              <Link
                to="#"
                className="logout"
                onClick={() => {
                  signout();
                  navigate("/");
                }}
              >
                <>Log Out</>
              </Link>
            </li>
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
