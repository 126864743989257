import { Box, Grid, Paper, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Logo from "../../components/icons/Logo";
import Navbar from "../../components/Navbar";
import useAuth from "../../hooks/useAuth";
import "./home.css";
import * as C from "./styles";
import axios from "../../api/api";

const Home = () => {
  const { signout, user } = useAuth();
  const navigate = useNavigate();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const [status, setStatus] = useState({});

  useEffect(() => {
    // Function to make the API request
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API + "/home"
        ); // Replace with your API endpoint
        setStatus(response.data);
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        // setLoading(false);
      }
    };

    fetchData(); // Call the function to fetch data when the component mounts
  }, []);
  return (
    <>
      <Navbar />
      <div className="customers">
        <div style={{ height: 400, width: "60%" }}>
          <Logo />
          <h1 className="title">Welcome, {user.name}</h1>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Item>
                  <div>
                    <p>Income amount for all companies this month</p>
                  </div>
                  <div>
                    <h2>£ {status?.incomes || "-"}</h2>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <div>
                    <p>Outcome amount for all companies this month</p>
                  </div>
                  <div>
                    <h2>£ {status?.outcomes || "-"}</h2>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <div>
                    <p>Profit amount for all companies this month</p>
                  </div>
                  <div>
                    <h2>£ {status?.profit || "-"}</h2>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <div>
                    <p>Amount of all scheduled services this month</p>
                  </div>
                  <div>
                    <h2>{status?.schedules || "-"}</h2>
                  </div>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
};
export default Home;

//TODO:
//User está sendo adicionado ao local-storage e não possui o atributo nome do usuário
//Pedir o acesso de Employee para realizar as alterações solicitadas - adicionar a página Home
