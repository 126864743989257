import React, { useEffect, useReducer } from "react";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridApi,
  GridCellValue
} from "@mui/x-data-grid";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Select,
  MenuItem,
  OutlinedInput
} from "@mui/material";
import { EmployeeInterface } from "../../interfaces/Employee";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";
import * as IoIcons from "react-icons/io";
import { alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import {
  deleteEmployee,
  getAllEmployees,
  getClients,
  getEmployeeId,
  rowEmployee,
  rowsClient,
  rowsEmployee,
  saveEmployee,
  updateEmployee
} from "../../models/employee";
import { getWeek, week, day } from "../../models/week";
import Logo from "../../components/icons/Logo";
import moment from "moment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { parse } from "date-fns";

import dayjs from "dayjs";
import {
  formatMoney,
  formatPhone,
  maskDate,
  validateData,
  validateEmail,
  validateMoney
} from "../../utils/utils";
import { validateDate } from "@mui/x-date-pickers/internals";
import ViewCustomer from "../../components/ViewCustomer";
import Navbar from "../../components/Navbar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxWidth: "100%",
  maxHeight: "100%",
  overflow: "scroll"
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

function createData(id, name, hours, frequency) {
  return { id, name, hours, frequency };
}

const initRows: rowsEmployee = [];
const initEmployee: EmployeeInterface = {
  id: 0,
  name: "",
  birthDate: new Date(),
  email: "",
  addressLine: "",
  hireDate: new Date(),
  phoneNumber: "",
  phoneNumber2: "",
  natInsurance: "",
  availableServiceTypes: "",
  paymentFrequency: "",
  paymentPerHour: 0,
  accountNumber: "",
  layoffDate: new Date(),
  active: true
};

type inputText = {
  value: string;
  error: boolean;
};
type Severity = "error" | "success" | "info" | "warning" | undefined;

function Employee() {
  const [employee, setEmployee] =
    React.useState<EmployeeInterface>(initEmployee);
  const [Custumers, setCustumers] = React.useState([]);
  const [CustomerId, setCustumerId] = React.useState(0);
  const [rowsCustomer, setRowsCustomer] = React.useState([]);
  const [EmployeeId, setEmployeeId] = React.useState(0);
  const [EmployeeName, setEmployeeName] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [Employeebirth, setEmployeeBirth] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [EmployeeAddress, setEmployeeAddress] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [EmployeePhone, setEmployeePhone] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [EmployeeAdcPhone, setEmployeeAdcPhone] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [EmployeeEmail, setEmployeeEmail] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [EmployeeHired, setEmployeeHired] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [EmployeeNatinsurance, setEmployeeNatinsurance] =
    React.useState<inputText>({ value: "", error: false });
  const [EmployeeDriving, setEmployeeDriving] = React.useState<boolean>(false);
  const [EmployeeBabysitting, setEmployeeBabysitting] =
    React.useState<boolean>(false);
  const [EmployeeVehicle, setEmployeeVehicle] = React.useState<boolean>(false);
  const [EmployeeAutonomous, setEmployeeAutonomous] =
    React.useState<boolean>(false);
  const [EmployeeCleaning, setEmployeeCleaning] =
    React.useState<boolean>(false);
  const [EmployeePayday, setEmployeePayday] = React.useState<inputText>({
    value: "weekly",
    error: false
  });
  const [EmployeeSalary, setEmployeeSalary] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [EmployeeAccountnumeric, setEmployeeAccountnumeric] =
    React.useState<inputText>({ value: "", error: false });
  const [EmployeeLayoff, setEmployeeLayoff] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [EmployeeActive, setEmployeeActive] = React.useState<boolean>(true);

  const [isNew, setIsNew] = React.useState<boolean>(false);
  const [isNewOrEdit, setIsNewOrEdit] = React.useState<boolean>(false);
  const [openEmployeeInfo, setOpenEmployeeInfo] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const handleOpenEmployeeInfo = () => setOpenEmployeeInfo(true);
  const handleCloseEmployeeInfo = () => setOpenEmployeeInfo(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [openEmployeeCreate, setOpenEmployeeCreate] = React.useState(false);
  const handleOpenEmployeeCreate = () => setOpenEmployeeCreate(true);
  const handleCloseEmployeeCreate = () => setOpenEmployeeCreate(false);
  const [openClientInfo, setOpenClientInfo] = React.useState(false);
  const handleOpenClientInfo = () => setOpenClientInfo(true);
  const handleCloseClientInfo = () => setOpenClientInfo(false);

  const [openModal, setOpenModal] = React.useState(false);

  const [rows, setRows] = React.useState<rowsEmployee>(initRows);
  const [countEmployees, setCountEmployees] = React.useState(0);
  const [sizeScreen, setSizeScreen] = React.useState({
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight
  });

  const [messageAlert, setMessageAlert] = React.useState("");
  const [typeMessage, setTypeMessage] = React.useState<Severity>("success");

  const [viewCustomer, setViewCustomer] = React.useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseOpen = () => {
    setOpen(false);
  };

  const [weekNew, setWeekNew] = React.useState(getWeek());
  const inputRefName = React.useRef<HTMLInputElement>();
  const inputRefAddress = React.useRef<HTMLInputElement>();
  const inputRefPhone = React.useRef<HTMLInputElement>();
  const inputRefAdcPhone = React.useRef<HTMLInputElement>();
  const inputRefEmail = React.useRef<HTMLInputElement>();
  const inputRefNatinsurance = React.useRef<HTMLInputElement>();
  const inputRefPayday = React.useRef<HTMLInputElement>();
  const inputRefSalary = React.useRef<HTMLInputElement>();
  const inputRefAccountnumeric = React.useRef<HTMLInputElement>();
  const inputRefLayoff = React.useRef<HTMLInputElement>();
  const inputRefBirth = React.useRef<HTMLInputElement>();
  const inputRefHired = React.useRef<HTMLInputElement>();

  const getEmployees = async () => {
    var r: rowsEmployee = [];
    const teste: rowsEmployee = await getAllEmployees();
    setRows(teste);
    forceUpdate();
    return r;
  };

  const getEmployee = async (id) =>
    new Promise<EmployeeInterface>(async (resolve, reject) => {
      try {
        const e: EmployeeInterface = await getEmployeeId(id);
        resolve(e);
        forceUpdate();
      } catch (error) {
        reject(initEmployee);
      }
    });

  const getCustumers = async (id: string) => {
    const cust: rowsClient = await getClients(id);
    const rowsTable = [];
    cust.forEach((element) => {
      rowsTable.push(
        createData(element.id, element.name, element.hours, element.frequency)
      );
    });
    setRowsCustomer(rowsTable);
    setCustumers(cust);
    forceUpdate();
  };
  const clearEmployee = () => {
    setEmployeeName({ value: "", error: false });
    setEmployeeBirth({ value: "", error: false });
    setEmployeeAddress({ value: "", error: false });
    setEmployeePhone({ value: "", error: false });
    setEmployeeAdcPhone({ value: "", error: false });
    setEmployeeEmail({ value: "", error: false });
    setEmployeeHired({ value: "", error: false });
    setEmployeeNatinsurance({ value: "", error: false });
    setEmployeeDriving(false);
    setEmployeeBabysitting(false);
    setEmployeeVehicle(false);
    setEmployeeAutonomous(false);
    setEmployeeCleaning(false);
    setEmployeePayday({ value: "weekly", error: false });
    setEmployeeSalary({ value: "", error: false });
    setEmployeeAccountnumeric({ value: "", error: false });
    setEmployeeLayoff({ value: "", error: false });
    setEmployeeActive(false);
  };

  const employeeView = async (id) => {
    const e: EmployeeInterface = await getEmployee(id);
    setEmployee(e);
    getCustumers(id);
    setOpenEmployeeInfo(true);
    forceUpdate();
  };

  const employeeEdit = async (id) => {
    const e: EmployeeInterface = await getEmployee(id);
    setEmployeeName({ value: e.name, error: false });
    setEmployeeBirth({
      value: e.birthDate ? moment(e.birthDate).format("DD/MM/YYYY") : "",
      error: false
    });
    setEmployeeAddress({ value: e.addressLine, error: false });
    setEmployeePhone({ value: e.phoneNumber, error: false });
    setEmployeeAdcPhone({ value: e.phoneNumber2, error: false });
    setEmployeeEmail({ value: e.email, error: false });
    setEmployeeHired({
      value: e.hireDate ? moment(e.hireDate).format("DD/MM/YYYY") : "",
      error: false
    });
    setEmployeeNatinsurance({ value: e.natInsurance, error: false });
    setEmployeeDriving(e.availableServiceTypes?.includes("driving") || false);
    setEmployeeBabysitting(
      e.availableServiceTypes?.includes("babysitting") || false
    );
    setEmployeeVehicle(e.availableServiceTypes?.includes("vehicle") || false);
    setEmployeeAutonomous(
      e.availableServiceTypes?.includes("autonomous") || false
    );
    setEmployeeCleaning(e.availableServiceTypes?.includes("clening") || false);
    setEmployeePayday({ value: e.paymentFrequency, error: false });
    setEmployeeSalary({
      value: parseFloat(e.paymentPerHour.toString()).toFixed(2) || "0.0",
      error: false
    });
    setEmployeeAccountnumeric({ value: e.accountNumber, error: false });
    setEmployeeLayoff({
      value: e.layoffDate ? moment(e.layoffDate).format("DD/MM/YYYY") : "",
      error: false
    });
    setEmployeeActive(e.active);
    setIsNew(false);
    setIsNewOrEdit(true);
    setEmployee(e);
    forceUpdate();
  };
  useEffect(() => {}, [sizeScreen]);

  useEffect(() => {
    getEmployees();
  }, []);
  const addRow = () => {
    var oldRows = rows;
    //var newId = Math.max(...oldRows.map(o => o.id));

    setRows(oldRows);
  };

  const updateRow = () => {
    // var r: rowsEmployee = getEmployees();
    // setRows(r);
  };

  React.useEffect(() => {
    inputRefName.current?.focus();
  }, [EmployeeName]);

  React.useEffect(() => {
    inputRefAddress.current?.focus();
  }, [EmployeeAddress]);

  React.useEffect(() => {
    inputRefPhone.current?.focus();
  }, [EmployeePhone]);

  React.useEffect(() => {
    inputRefAdcPhone.current?.focus();
  }, [EmployeeAdcPhone]);
  React.useEffect(() => {
    inputRefEmail.current?.focus();
  }, [EmployeeEmail]);

  React.useEffect(() => {
    inputRefNatinsurance.current?.focus();
  }, [EmployeeNatinsurance]);
  React.useEffect(() => {
    inputRefPayday.current?.focus();
  }, [EmployeePayday]);
  React.useEffect(() => {
    inputRefSalary.current?.focus();
  }, [EmployeeSalary]);

  React.useEffect(() => {
    inputRefAccountnumeric.current?.focus();
  }, [EmployeeAccountnumeric]);

  React.useEffect(() => {
    inputRefLayoff.current?.focus();
  }, [EmployeeLayoff]);

  React.useEffect(() => {
    inputRefBirth.current?.focus();
  }, [Employeebirth]);

  React.useEffect(() => {
    inputRefHired.current?.focus();
  }, [EmployeeHired]);

  useEffect(() => {
    setCountEmployees(rows.length);
  }, [rows, weekNew]);

  useEffect(() => {
    var w: week = weekNew;
    setWeekNew(w);
  }, [weekNew]);
  const updateWeekNew = (id: number) => {
    var w: week = weekNew;
    w.forEach((element) => {
      if (element.dayId === id) element.enabled = !element.enabled;
    });
    setWeekNew(w);
    forceUpdate();
  };

  const onCloseNew = () => {
    setIsNewOrEdit(false);
  };

  const handleChange = (event) => {
    switch (event.target.id) {
      case "name":
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setEmployeeName(value);
        break;
      case "address":
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setEmployeeAddress(value);
        break;
      case "phone":
        var value: inputText = {
          value: formatPhone(event.target.value, EmployeePhone.value),
          error: event.target.value.length === 0
        };
        setEmployeePhone(value);
        break;
      case "adPhone":
        var value: inputText = {
          value: formatPhone(event.target.value, EmployeeAdcPhone.value),
          error: event.target.value.length === 0
        };
        setEmployeeAdcPhone(value);
        break;
      case "email":
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setEmployeeEmail(value);
        break;
      case "natinsurance": {
        const regex = /^[0-9\b]+$/;
        if (event.target.value === "" || regex.test(event.target.value)) {
          var value: inputText = {
            value: event.target.value,
            error: event.target.value.length === 0
          };
          setEmployeeNatinsurance(value);
        }
        break;
      }
      case "salary": {
        var value: inputText = {
          value: formatMoney(event.target.value, EmployeeSalary.value),
          error: event.target.value.length === 0
        };
        setEmployeeSalary(value);
        break;
      }
      case "accountnumeric": {
        const regex = /^[0-9\b]+$/;
        if (event.target.value === "" || regex.test(event.target.value)) {
          var value: inputText = {
            value: event.target.value,
            error: event.target.value.length === 0
          };
          setEmployeeAccountnumeric(value);
        }
        break;
      }
      case "layoff": {
        var value: inputText = {
          value: maskDate(event.target.value, EmployeeLayoff.value),
          error: false
        };
        setEmployeeLayoff(value);

        break;
      }
      case "birth": {
        var value: inputText = {
          value: maskDate(event.target.value, Employeebirth.value),
          error: false
        };
        setEmployeeBirth(value);

        break;
      }
      case "hired": {
        var value: inputText = {
          value: maskDate(event.target.value, EmployeeHired.value),
          error: false
        };
        setEmployeeHired(value);

        break;
      }
    }
    switch (event.target.name) {
      case "payday":
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setEmployeePayday(value);
        break;
    }
  };

  const handleCheckChange = (event) => {
    switch (event.target.name) {
      case "driving":
        setEmployeeDriving(event.target.checked);
        break;
      case "babysitting":
        setEmployeeBabysitting(event.target.checked);
        break;
      case "vehicle":
        setEmployeeVehicle(event.target.checked);
        break;
      case "autonomous":
        setEmployeeAutonomous(event.target.checked);
        break;
      case "cleaning":
        setEmployeeCleaning(event.target.checked);
        break;
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsValid(false);
  };

  const validateFilds = () => {
    var isValid = true;
    if (EmployeeName.value === "") {
      setEmployeeName((emp) => {
        return { ...emp, error: true };
      });
      isValid = false;
    }
    if (EmployeeEmail.value === "" || !validateEmail(EmployeeEmail.value)) {
      setEmployeeEmail((emp) => {
        return { ...emp, error: true };
      });
      isValid = false;
    }
    if (EmployeeAddress.value === "") {
      setEmployeeAddress((emp) => {
        return { ...emp, error: true };
      });
      isValid = false;
    }
    if (EmployeePhone.value === "" || EmployeePhone.value.length < 11) {
      setEmployeePhone((emp) => {
        return { ...emp, error: true };
      });
      isValid = false;
    }
    if (EmployeeAdcPhone.value === "" || EmployeeAdcPhone.value.length < 11) {
      setEmployeeAdcPhone((emp) => {
        return { ...emp, error: true };
      });
      isValid = false;
    }
    if (EmployeeNatinsurance.value === "") {
      setEmployeeNatinsurance((emp) => {
        return { ...emp, error: true };
      });
      isValid = false;
    }
    if (EmployeePayday.value === "") {
      setEmployeePayday((emp) => {
        return { ...emp, error: true };
      });
      isValid = false;
    }
    if (EmployeeSalary.value === "" || !validateMoney(EmployeeSalary.value)) {
      setEmployeeSalary((emp) => {
        return { ...emp, error: true };
      });
      isValid = false;
    }
    if (EmployeeAccountnumeric.value === "") {
      setEmployeeAccountnumeric((emp) => {
        return { ...emp, error: true };
      });
      isValid = false;
    }
    if (Employeebirth.value === "" || !validateData(Employeebirth.value)) {
      setEmployeeBirth((emp) => {
        return { ...emp, error: true };
      });
      isValid = false;
    }
    if (EmployeeHired.value === "" || !validateData(EmployeeHired.value)) {
      setEmployeeHired((emp) => {
        return { ...emp, error: true };
      });
      isValid = false;
    }
    if (EmployeeLayoff.value !== "" && !validateData(EmployeeLayoff.value)) {
      setEmployeeLayoff((emp) => {
        return { ...emp, error: true };
      });
      isValid = false;
    }
    return isValid;
  };

  const EmployeeDelete = async () => {
    try {
      var del: boolean;

      del = await deleteEmployee(EmployeeId.toString());
      setIsValid(del);
      setMessageAlert("Delete success!");
      setTypeMessage("success");
      handleCloseOpen();
      getEmployees();
      forceUpdate();
    } catch (error) {
      setIsValid(true);
      setMessageAlert("Delete fail!");
      setTypeMessage("error");
    }
  };
  const saveNewEmployee = async () => {
    var validFilds = validateFilds();
    if (validFilds) {
      var emp: EmployeeInterface = {
        id: employee.id,
        name: EmployeeName.value,
        birthDate: parse(Employeebirth.value, "dd/MM/yyyy", new Date()),
        email: EmployeeEmail.value,
        addressLine: EmployeeAddress.value,
        hireDate: parse(EmployeeHired.value, "dd/MM/yyyy", new Date()),
        phoneNumber: EmployeePhone.value,
        phoneNumber2: EmployeeAdcPhone.value,
        natInsurance: EmployeeNatinsurance.value,
        availableServiceTypes: [
          EmployeeDriving ? "driving" : "",
          EmployeeBabysitting ? "babysitting" : "",
          EmployeeVehicle ? "vehicle" : "",
          EmployeeAutonomous ? "autonomous" : "",
          EmployeeCleaning ? "clening" : ""
        ].join(","),
        paymentFrequency: EmployeePayday.value,
        paymentPerHour: parseFloat(EmployeeSalary.value),
        accountNumber: EmployeeAccountnumeric.value,
        layoffDate: null,
        active: EmployeeActive
      };
      try {
        var save: boolean;
        if (isNew) save = await saveEmployee(emp);
        else {
          save = await updateEmployee(emp);
        }
        setIsValid(save);
        setMessageAlert("Save success!");
        setTypeMessage("success");
        setIsNew(false);
        setIsNewOrEdit(false);
        getEmployees();
        clearEmployee();
      } catch (error) {
        setIsValid(true);
        setMessageAlert("Save error!");
        setTypeMessage("error");
      }
    } else {
      setIsValid(true);
      setMessageAlert("Fill in the fields correctly!");
      setTypeMessage("error");
    }
  };
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "Name", headerName: "Name", width: 210 },
    { field: "Salary", headerName: "Salary (£)", width: 120 },
    { field: "Payday", headerName: "Payday", width: 160 },
    { field: "Phone", headerName: "Phone", width: 190 },
    { field: "AdcPhone", headerName: "Adc Phone", width: 190 },
    { field: "active", renderCell: (params: any) => <>{params.row.active?"Active":"Inative"}</>, headerName: "Status", width: 60 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params: any) => {
        return (
          <>
            <div>
              {/* <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={() => {
                  handleClickOpen();
                  setEmployeeId(params.id);
                  // customerDelete(params.id)
                }}
              >
                <AiIcons.AiFillDelete />
              </IconButton> */}
              <IconButton
                color="primary"
                component="label"
                onClick={() => employeeEdit(params.id)}
              >
                <AiIcons.AiFillEdit />
              </IconButton>
              <IconButton
                color="primary"
                component="label"
                onClick={() => employeeView(params.id)}
              >
                <AiIcons.AiFillInfoCircle />
              </IconButton>
            </div>
          </>
        );
      }
    }
  ];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
    border: theme.spacing(10),
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);"
  }));
  const RenderHeader = (props) => {
    return <div></div>;
  };
  return (
    <>
      <Navbar />
      <div className="customers">
        <div style={{ height: 400, width: 1150 }}>
          <div>
            <Dialog
              open={open}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Delete employee?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Confirm to delete the employee
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseOpen}>Cancel</Button>
                <Button onClick={() => EmployeeDelete()}>Confirm</Button>
              </DialogActions>
            </Dialog>
          </div>
          <Snackbar
            open={isValid}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert severity={typeMessage} sx={{ width: "100%" }}>
              {messageAlert}
            </Alert>
          </Snackbar>
          <Logo />
          <div>
            <>
              <Modal
                style={{ maxWidth: "100%" }}
                open={openClientInfo}
                onClose={handleCloseClientInfo}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <>
                  <ViewCustomer
                    onClose={handleCloseClientInfo}
                    customerId={CustomerId}
                  ></ViewCustomer>
                </>
              </Modal>
            </>
            {/* region modal viewEmployee */}
            <>
              <Modal
                open={openEmployeeInfo}
                onClose={handleCloseEmployeeInfo}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ flexDirection: "row-reverse", display: "flex" }}>
                      <IconButton onClick={handleCloseEmployeeInfo}>
                        <AiIcons.AiOutlineClose />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={2}
                      boxShadow="0px 2px 0px 0px rgb(0 0 0 / 20%),0px 2px 0px 0px rgb(0 0 0 / 20%)"
                      marginBottom="23px"
                    >
                      <Grid item xs={6}>
                        <Item>
                          <div>
                            <p>
                              <b>{employee.name}</b>
                            </p>
                          </div>
                          <div>
                            <p>
                              <b>E-Mail: </b>
                              {employee.email}
                            </p>
                          </div>
                          <div>
                            <p>
                              <b>Address: </b>
                              {employee.addressLine}
                            </p>
                          </div>
                          <div>
                            <p>
                              <b>Hiring Date: </b>
                              <>
                                {employee.hireDate
                                  ? moment(employee.hireDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "  --"}
                              </>
                            </p>
                          </div>
                        </Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>
                          <div>
                            <p>
                              <b>Phone Number: </b>
                              {employee.phoneNumber}
                            </p>
                          </div>
                          <div>
                            <p>
                              <b>Phone Number: </b>
                              {employee.phoneNumber2}
                            </p>
                          </div>
                          <div>
                            <p>
                              <b>Date of birth: </b>
                              <>
                                {moment(employee.birthDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </>
                            </p>
                          </div>
                          <div>
                            <p>
                              <b>Nat insurance: </b>
                              <>{employee.natInsurance}</>
                            </p>
                          </div>
                        </Item>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      boxShadow="0px 2px 0px 0px rgb(0 0 0 / 20%),0px 2px 0px 0px rgb(0 0 0 / 20%)"
                      marginBottom="23px"
                    >
                      <Grid item xs={6}>
                        <Item>
                          <div>
                            <p>
                              <b>Driving: </b>
                              <>
                                {employee.availableServiceTypes.includes(
                                  "driving"
                                )
                                  ? "yes"
                                  : "no"}
                              </>
                            </p>
                          </div>
                          <div>
                            <p>
                              <b>Babysitting: </b>
                              <>
                                {employee.availableServiceTypes.includes(
                                  "babysitting"
                                )
                                  ? "yes"
                                  : "no"}
                              </>
                            </p>
                          </div>
                          <div>
                            <p>
                              <b>Vehicle: </b>
                              <>
                                {employee.availableServiceTypes.includes(
                                  "vehicle"
                                )
                                  ? "yes"
                                  : "no"}
                              </>
                            </p>
                          </div>
                        </Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>
                          <div>
                            <p>
                              <b>Autonomous: </b>
                              <>
                                {employee.availableServiceTypes.includes(
                                  "autonomous"
                                )
                                  ? "yes"
                                  : "no"}
                              </>
                            </p>
                          </div>
                          <div>
                            <p>
                              <b>Cleaning: </b>
                              <>
                                {employee.availableServiceTypes.includes(
                                  "cleaning"
                                )
                                  ? "yes"
                                  : "no"}
                              </>
                            </p>
                          </div>
                          <div>
                            <p>
                              <b> </b>
                              <>
                                <br />
                              </>
                            </p>
                          </div>
                        </Item>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Item>
                          <div>
                            <p>
                              <b>Salary: </b>
                              {`${employee.paymentPerHour} £/h`}
                            </p>
                          </div>
                          <div>
                            <p>
                              <b>Payment frequency: </b>
                              {employee.paymentFrequency}
                            </p>
                          </div>
                          <div>
                            <p>
                              <b>Status: </b>
                              <>{employee.active ? "Active" : "Inactive"}</>
                            </p>
                          </div>
                        </Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>
                          <div>
                            <p>
                              <b>Frequency: </b>
                              Week
                            </p>
                          </div>
                          <div>
                            <p>
                              <b>Account Numeric: </b>
                              {employee.accountNumber}
                            </p>
                          </div>
                          <>
                            {employee.layoffDate ?? (
                              <div>
                                <p>
                                  <b>Layoff: </b>
                                  <>{employee.layoffDate || "-"}</>
                                </p>
                              </div>
                            )}
                          </>
                        </Item>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Item>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 700 }}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Name</StyledTableCell>
                                  <StyledTableCell align="left">
                                    Prevision hours
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    Frequency days
                                  </StyledTableCell>
                                  <StyledTableCell align="left"></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rowsCustomer.map((rowCustumer) => (
                                  <StyledTableRow key={rowCustumer.name}>
                                    <StyledTableCell component="th" scope="row">
                                      {rowCustumer.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {rowCustumer.hours} hours
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {rowCustumer.frequency}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      <IconButton
                                        color="primary"
                                        component="label"
                                        onClick={() => {
                                          setCustumerId(rowCustumer.id);
                                          setOpenClientInfo(true);
                                        }}
                                      >
                                        <AiIcons.AiFillInfoCircle />
                                      </IconButton>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Item>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Modal>
            </>
            {/* endregion */}
          </div>
          {isNewOrEdit ? (
            <>
              <Box sx={style}>
                <Box sx={{ flexGrow: 1 }}>
                  <Box sx={{ flexDirection: "row-reverse", display: "flex" }}>
                    <IconButton onClick={onCloseNew}>
                      <AiIcons.AiOutlineClose />
                    </IconButton>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {`${isNew ? "New" : "Edit"} Employee Registration`}
                  </Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            id="name"
                            label="Complete Name"
                            variant="standard"
                            error={EmployeeName?.error}
                            value={EmployeeName?.value}
                            inputRef={inputRefName}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={2}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            id="birth"
                            label="Birth date"
                            variant="standard"
                            error={Employeebirth?.error}
                            value={Employeebirth?.value}
                            inputRef={inputRefBirth}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            size="medium"
                            id="address"
                            label="Address"
                            variant="standard"
                            error={EmployeeAddress?.error}
                            value={EmployeeAddress.value}
                            inputRef={inputRefAddress}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            size="medium"
                            id="phone"
                            label="Phone Number"
                            variant="standard"
                            error={EmployeePhone?.error}
                            value={EmployeePhone.value}
                            inputRef={inputRefPhone}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            size="medium"
                            id="adPhone"
                            label="Phone Number Adc"
                            variant="standard"
                            error={EmployeeAdcPhone?.error}
                            value={EmployeeAdcPhone.value}
                            inputRef={inputRefAdcPhone}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            size="medium"
                            id="email"
                            label="Email"
                            variant="standard"
                            error={EmployeeEmail?.error}
                            value={EmployeeEmail.value}
                            inputRef={inputRefEmail}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={2}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            id="hired"
                            label="Birth Hired"
                            variant="standard"
                            error={EmployeeHired?.error}
                            value={EmployeeHired?.value}
                            inputRef={inputRefHired}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            size="medium"
                            id="natinsurance"
                            label="Nat Insurance"
                            variant="standard"
                            error={EmployeeNatinsurance.error}
                            value={EmployeeNatinsurance.value}
                            inputRef={inputRefNatinsurance}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Item>
                        <div>
                          <FormControl component="fieldset" variant="standard">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={EmployeeDriving}
                                    onChange={handleCheckChange}
                                    name="driving"
                                  />
                                }
                                label="Driving"
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={2}>
                      <Item>
                        <div>
                          <FormControl component="fieldset" variant="standard">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={EmployeeBabysitting}
                                    onChange={handleCheckChange}
                                    name="babysitting"
                                  />
                                }
                                label="Babysitting"
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={2}>
                      <Item>
                        <div>
                          <FormControl component="fieldset" variant="standard">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={EmployeeVehicle}
                                    onChange={handleCheckChange}
                                    name="vehicle"
                                  />
                                }
                                label="Vehicle"
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={2}>
                      <Item>
                        <div>
                          <FormControl component="fieldset" variant="standard">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={EmployeeAutonomous}
                                    onChange={handleCheckChange}
                                    name="autonomous"
                                  />
                                }
                                label="Autonomous"
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={2}>
                      <Item>
                        <div>
                          <FormControl component="fieldset" variant="standard">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={EmployeeCleaning}
                                    onChange={handleCheckChange}
                                    name="cleaning"
                                  />
                                }
                                label="Cleaning"
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Payday
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="payday"
                              id="payday"
                              value={EmployeePayday.value}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="weekly"
                                control={<Radio />}
                                label="Weekly"
                              />
                              <FormControlLabel
                                value="biweekly"
                                control={<Radio />}
                                label="Biweekly"
                              />
                              <FormControlLabel
                                value="monthly"
                                control={<Radio />}
                                label="Monthly"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                            variant="standard"
                          >
                            <InputLabel htmlFor="standard-adornment-amount">
                              Salary
                            </InputLabel>
                            <Input
                              id="salary"
                              error={EmployeeSalary.error}
                              value={EmployeeSalary.value}
                              inputRef={inputRefSalary}
                              onChange={handleChange}
                              startAdornment={
                                <InputAdornment position="start">
                                  £
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            size="medium"
                            id="accountnumeric"
                            label="Account Numeric"
                            variant="standard"
                            error={EmployeeAccountnumeric.error}
                            value={EmployeeAccountnumeric.value}
                            inputRef={inputRefAccountnumeric}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                    <>
                      {!isNew ? (
                        <Grid item xs={3}>
                          <Item>
                            <div>
                              <FormControl sx={{ width: "100%" }}>
                                <InputLabel id="demo-multiple-name-label">
                                  Layoff
                                </InputLabel>
                                <Select
                                  labelId="demo-multiple-name-label"
                                  id="demo-multiple-name"
                                  value={EmployeeActive ? "active" : "inactive"}
                                  input={<OutlinedInput label="active" />}
                                  onChange={(event) => {
                                    setEmployeeActive(
                                      event.target.value === "active"
                                    );
                                  }}
                                >
                                  <MenuItem key={"active"} value={"active"}>
                                    active
                                  </MenuItem>
                                  <MenuItem key={"inactive"} value={"inactive"}>
                                    inactive
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </Item>
                        </Grid>
                      ) : null}
                    </>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      container
                      xs={12}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Item>
                        <div>
                          <Button variant="contained" onClick={saveNewEmployee}>
                            Save
                          </Button>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <div>
                <Box>
                  <Grid
                    container
                    spacing={2}
                    boxShadow="0px 2px 0px 0px rgb(0 0 0 / 20%),0px 2px 0px 0px rgb(0 0 0 / 20%)"
                    marginBottom="23px"
                  >
                    <Grid item xs={3}>
                      <Item style={{ background: "rgba(0,0,0,0%)" }}>
                        <div>
                          <p style={{ fontSize: 20 }}>
                            Total Active Employees <b>{countEmployees}</b>
                          </p>
                        </div>
                      </Item>
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Item style={{ background: "rgba(0,0,0,0%)" }}>
                        <div>
                          <IconButton
                            color="primary"
                            component="label"
                            onClick={() => {
                              setIsNew(true);
                              setIsNewOrEdit(true);
                              setEmployee(initEmployee);
                              clearEmployee();
                              //setOpenEmployeeCreate(true)
                            }}
                          >
                            <IoIcons.IoMdAddCircle />
                          </IconButton>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
              </div>

              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5]}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Employee;
