import {
  Alert,
  Box,
  Button,
  Checkbox,
  createTheme,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  Stack,
  styled,
  Switch,
  SwitchProps,
  TextField,
  ThemeProvider,
  Typography
} from "@mui/material";

import Loading from "react-fullscreen-loading";
import { DataGrid, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import React, { useEffect, useReducer, useState } from "react";
import Logo from "../../components/icons/Logo";
import Navbar from "../../components/Navbar";
import {
  addApontments,
  deleteAppointment,
  gerateScheduling,
  getAllScheduling,
  getAppointmentClient,
  rowsScheduling,
  updateAppointment
} from "../../models/calendar";
import { ContainerMain } from "../../styles/Home";
import {
  formatMoney,
  maskDate,
  monthNames,
  Severity,
  validateData,
  formatDateIgnoringTimezone
} from "../../utils/utils";
import * as IoIcons from "react-icons/io";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from "dayjs";
import {
  AppointmentClient,
  CalendarAppointment,
  calendarData,
  CalendarEmployee
} from "../../interfaces/CheckList";
import { blue } from "@mui/material/colors";
import InboxIcon from "@mui/icons-material/Inbox";
import Person2Icon from "@mui/icons-material/Person2";
import * as AiIcons from "react-icons/ai";
import { getWeek, week, WeekTable } from "../../models/week";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme
} from "@mui/material/styles";
import { getAllEmployees, rowsEmployee } from "../../models/employee";
import { getClients, rowsClient } from "../../models/clients";
import moment from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import engb from "date-fns/locale/en-GB";
import Dialog from "../../modals/dialogView";
import axios from "../../api/api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  border: theme.spacing(10),
  boxShadow:
    "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);"
}));

const FireNav = styled(List)<{ component?: React.ElementType }>({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20
  }
});

const CelScheduling = (props) => {
  return <></>;
};
const initRows: CalendarEmployee[] = [];

function getStyles(name, id, theme) {
  return {
    fontWeight: theme.typography.fontWeightRegular
  };
}

function Calendar() {
  const [generatingCalendar, setGeneratingCalendar] = useState(false);

  const [calendarStatus, setCalendarStatus] = useState(null);
  const fetchCalendarStatus = async () => {
    let response = await axios.get(
      process.env.REACT_APP_API + "/service/status"
    );
    if (response.data) {
      setCalendarStatus(response.data);
    }
  };
  useEffect(() => {
    fetchCalendarStatus();
  }, []);

  const theme = useTheme();

  type inputProfecional = {
    name: string;
    id: string;
    error: boolean;
  };

  type inputText = {
    value: string;
    error: boolean;
  };

  var initWeekDays: WeekTable = {
    Friday: "",
    Monday: "",
    Tuesday: "",
    Wednesday: "",
    Thursday: "",
    Saturday: "",
    Sunday: ""
  };

  const initAppointmentClientView: AppointmentClient = {
    tp_date: new Date(),
    ct_name: "",
    t_dy_client_id_client: 0,
    id_calendar: 0,
    is_temp: "n",
    pr_name: "",
    pr_email: "",
    py_cash: 0,
    ex_cost: 0,
    hr_worked: "",
    vl_salary: 0,
    fr_hour: 0,
    dt_paymenttype: "",
    id_professional: 0,
    id_employee: 0,
    status: "pending"
  };
  type Severity = "error" | "success" | "info" | "warning" | undefined;

  const [listEventsCalendar, setListEventsCalendar] = useState<any[]>([]);
  const [rows, setRows] = React.useState<CalendarEmployee[]>(initRows);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [curentMonth, setCurentMonth] = React.useState<string>("");
  const [dateInitReference, setDateInitReference] =
    React.useState<Date | null>();
  const [isValid, setIsValid] = React.useState(false);
  const [typeMessage, setTypeMessage] = React.useState<Severity>("success");
  const [defaultDate, setDefaultDate] = React.useState<Date | null>();
  const [open, setOpen] = React.useState(true);
  const [Employees, setEmployees] = React.useState([]);
  const [Clients, setClients] = React.useState([]);
  const [WeekTableDays, setWeekTableDays] =
    React.useState<WeekTable>(initWeekDays);
  const [AppointmentClientView, setAppointmentClientView] = React.useState(
    initAppointmentClientView
  );
  const [deleteDialog, setdeleteDialog] = useState(false);

  const [openServiceCreate, setOpenServiceCreate] = React.useState(false);
  const handleOpenServiceCreate = () => setOpenServiceCreate(true);
  const handleCloseServiceCreate = () => setOpenServiceCreate(false);

  const [openServiceView, setOpenServiceView] = React.useState(false);
  const handleOpenServiceView = () => setOpenServiceView(true);
  const handleCloseServiceView = () => setOpenServiceView(false);

  const [weekNew, setWeekNew] = React.useState(getWeek());

  const [isNew, setIsNew] = React.useState(true);
  const [isNewOrEdit, setIsNewOrEdit] = React.useState<boolean>(false);

  const [IdProfessional, setIdProfessional] = React.useState<inputProfecional>({
    name: "",
    id: "",
    error: false
  });
  const [IdClient, setIdClient] = React.useState<inputProfecional>({
    name: "",
    id: "",
    error: false
  });
  const [DateStart, setDateStart] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [DateEnd, setDateEnd] = React.useState<inputText>({
    value: "",
    error: false
  });

  const [payInCash, setPayInCash] = React.useState("");
  const [extraCost, setExtraCost] = React.useState("");
  const [workHours, setworkHours] = React.useState("");

  const inputRefIdProfessional = React.useRef<HTMLInputElement>();
  const inputRefIdClient = React.useRef<HTMLInputElement>();
  const inputRefDateStart = React.useRef<HTMLInputElement>();
  const inputRefDateEnd = React.useRef<HTMLInputElement>();
  const inputRefDateReference = React.useRef<HTMLInputElement>();
  const inputRefDateEdit = React.useRef<HTMLInputElement>();

  const inputRefExtraCost = React.useRef<HTMLInputElement>();
  const inputRefPayInCash = React.useRef<HTMLInputElement>();
  const inputRefWorkHours = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    inputRefIdProfessional.current?.focus();
  }, [IdProfessional]);
  React.useEffect(() => {
    inputRefIdClient.current?.focus();
  }, [IdClient]);
  React.useEffect(() => {
    inputRefDateStart.current?.focus();
  }, [DateStart]);
  React.useEffect(() => {
    inputRefDateEnd.current?.focus();
  }, [DateEnd]);
  React.useEffect(() => {
    inputRefDateReference.current?.focus();
  }, [dateInitReference]);
  React.useEffect(() => {
    inputRefDateEdit.current?.focus();
  }, [AppointmentClientView]);
  React.useEffect(() => {
    inputRefPayInCash.current?.focus();
  }, [payInCash]);
  React.useEffect(() => {
    inputRefExtraCost.current?.focus();
  }, [extraCost]);
  React.useEffect(() => {
    inputRefWorkHours.current?.focus();
  }, [workHours]);

  const getAppointmentClientView = async (id: Number) => {
    var a: AppointmentClient = await getAppointmentClient(id);
    setAppointmentClientView(a);
    forceUpdate();
  };
  const getEmployees = async () => {
    var e = [];
    const emplos: rowsEmployee = await getAllEmployees();
    emplos.forEach((element) => {
      var user = {
        name: element.Name,
        id: element.id,
        email: element.Email
      };
      e.push(user);
    });
    setEmployees(e);

    forceUpdate();
  };
  const getCls = async () => {
    var r = [];
    const cls: rowsClient = await getClients();
    cls.forEach((element) => {
      var user = {
        name: element.company,
        id: element.id
      };
      r.push(user);
    });
    setClients(r);
    forceUpdate();
  };
  const clearService = () => {
    setIdProfessional({ name: "", id: "", error: false });
    setIdClient({ name: "", id: "", error: false });
    var w = weekNew;
    weekNew.forEach((element) => {
      element.enabled = false;
    });
    setWeekNew(w);
    setDateStart({ error: false, value: "" });
    setDateEnd({ error: false, value: "" });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsValid(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    maxWidth: "100%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
  };
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "pr_name", headerName: "Name", width: 200 },
    {
      field: "Monday",
      renderHeader: (params: any) => {
        return (
          <>
            <strong>{`Monday ${WeekTableDays?.Monday}`}</strong>
          </>
        );
      },
      sortable: false,
      width: 175,
      renderCell: (params: any) => {
        return (
          <>
            <Box
              sx={{ display: "flex", width: "100%", flexDirection: "column" }}
            >
              {params.row.Monday.map((item, index) => {
                return (
                  <div key={index}>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{
                        width: "100%",
                        minWidth: "100%",
                        paddingBottom: 2
                      }}
                    >
                      <></>
                      <Button
                        title={item.ct_name}
                        variant="outlined"
                        color={item.is_temp === "n" ? "primary" : "warning"}
                        startIcon={<Person2Icon />}
                        style={{
                          width: "100%",
                          minWidth: "100%",
                          justifyContent: "left"
                        }}
                        onClick={() => {
                          getAppointmentClientView(item.id_calendar);
                          setOpenServiceView(true);
                        }}
                      >
                        {item.ct_name.length > 13
                          ? item.ct_name.substring(0, 10) + "..."
                          : item.ct_name}
                      </Button>
                    </Stack>
                  </div>
                );
              })}
            </Box>
          </>
        );
      }
    },
    {
      field: "Tuesday",
      renderHeader: (params: any) => {
        return (
          <>
            <strong>{`Tuesday ${WeekTableDays?.Tuesday}`}</strong>
          </>
        );
      },
      sortable: false,
      width: 175,
      renderCell: (params: any) => {
        return (
          <>
            <Box
              sx={{ display: "flex", width: "100%", flexDirection: "column" }}
            >
              {params.row.Tuesday.map((item, index) => {
                return (
                  <div key={index}>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{
                        width: "100%",
                        minWidth: "100%",
                        paddingBottom: 2
                      }}
                    >
                      <Button
                        title={item.ct_name}
                        variant="outlined"
                        color={item.is_temp === "n" ? "primary" : "warning"}
                        startIcon={<Person2Icon />}
                        style={{
                          width: "100%",
                          minWidth: "100%",
                          justifyContent: "left"
                        }}
                        onClick={() => {
                          getAppointmentClientView(item.id_calendar);
                          setOpenServiceView(true);
                        }}
                      >
                        {item.ct_name.length > 13
                          ? item.ct_name.substring(0, 10) + "..."
                          : item.ct_name}
                      </Button>
                    </Stack>
                  </div>
                );
              })}
            </Box>
          </>
        );
      }
    },
    {
      field: "Wednesday",
      renderHeader: (params: any) => {
        return (
          <>
            <strong>{`Wednesday ${WeekTableDays?.Wednesday}`}</strong>
          </>
        );
      },
      sortable: false,
      width: 175,
      renderCell: (params: any) => {
        return (
          <>
            <Box
              sx={{ display: "flex", width: "100%", flexDirection: "column" }}
            >
              {params.row.Wednesday.map((item, index) => {
                return (
                  <div key={index}>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{
                        width: "100%",
                        minWidth: "100%",
                        paddingBottom: 2
                      }}
                    >
                      <Button
                        title={item.ct_name}
                        variant="outlined"
                        color={item.is_temp === "n" ? "primary" : "warning"}
                        startIcon={<Person2Icon />}
                        style={{
                          width: "100%",
                          minWidth: "100%",
                          justifyContent: "left"
                        }}
                        onClick={() => {
                          getAppointmentClientView(item.id_calendar);
                          setOpenServiceView(true);
                        }}
                      >
                        {item.ct_name.length > 13
                          ? item.ct_name.substring(0, 10) + "..."
                          : item.ct_name}
                      </Button>
                    </Stack>
                  </div>
                );
              })}
            </Box>
          </>
        );
      }
    },
    {
      field: "Thursday",
      renderHeader: (params: any) => {
        return (
          <>
            <strong>{`Thursday ${WeekTableDays?.Thursday}`}</strong>
          </>
        );
      },
      sortable: false,
      width: 175,
      renderCell: (params: any) => {
        return (
          <>
            <Box
              sx={{ display: "flex", width: "100%", flexDirection: "column" }}
            >
              {params.row.Thursday.map((item, index) => {
                return (
                  <div key={index}>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{
                        width: "100%",
                        minWidth: "100%",
                        paddingBottom: 2
                      }}
                    >
                      <Button
                        title={item.ct_name}
                        variant="outlined"
                        color={item.is_temp === "n" ? "primary" : "warning"}
                        startIcon={<Person2Icon />}
                        style={{
                          width: "100%",
                          minWidth: "100%",
                          justifyContent: "left"
                        }}
                        onClick={() => {
                          getAppointmentClientView(item.id_calendar);
                          setOpenServiceView(true);
                        }}
                      >
                        {item.ct_name.length > 13
                          ? item.ct_name.substring(0, 10) + "..."
                          : item.ct_name}
                      </Button>
                    </Stack>
                  </div>
                );
              })}
            </Box>
          </>
        );
      }
    },
    {
      field: "Friday",
      renderHeader: (params: any) => {
        return (
          <>
            <strong>{`Friday ${WeekTableDays?.Friday}`}</strong>
          </>
        );
      },
      sortable: false,
      width: 175,
      renderCell: (params: any) => {
        return (
          <>
            <Box
              sx={{ display: "flex", width: "100%", flexDirection: "column" }}
            >
              {params.row.Friday.map((item, index) => {
                return (
                  <div key={index}>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{
                        width: "100%",
                        minWidth: "100%",
                        paddingBottom: 2
                      }}
                    >
                      <Button
                        title={item.ct_name}
                        variant="outlined"
                        color={item.is_temp === "n" ? "primary" : "warning"}
                        startIcon={<Person2Icon />}
                        style={{
                          width: "100%",
                          minWidth: "100%",
                          justifyContent: "left"
                        }}
                        onClick={() => {
                          getAppointmentClientView(item.id_calendar);
                          setOpenServiceView(true);
                        }}
                      >
                        {item.ct_name.length > 13
                          ? item.ct_name.substring(0, 10) + "..."
                          : item.ct_name}
                      </Button>
                    </Stack>
                  </div>
                );
              })}
            </Box>
          </>
        );
      }
    },
    {
      field: "Saturday",
      renderHeader: (params: any) => {
        return (
          <>
            <strong>{`Saturday ${WeekTableDays?.Saturday}`}</strong>
          </>
        );
      },
      sortable: false,
      width: 175,
      renderCell: (params: any) => {
        return (
          <>
            <Box
              sx={{ display: "flex", width: "100%", flexDirection: "column" }}
            >
              {params.row.Saturday.map((item, index) => {
                return (
                  <div key={index}>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{
                        width: "100%",
                        minWidth: "100%",
                        paddingBottom: 2
                      }}
                    >
                      <Button
                        title={item.ct_name}
                        variant="outlined"
                        color={item.is_temp === "n" ? "primary" : "warning"}
                        startIcon={<Person2Icon />}
                        style={{
                          width: "100%",
                          minWidth: "100%",
                          justifyContent: "left"
                        }}
                        onClick={() => {
                          getAppointmentClientView(item.id_calendar);
                          setOpenServiceView(true);
                        }}
                      >
                        {item.ct_name.length > 13
                          ? item.ct_name.substring(0, 10) + "..."
                          : item.ct_name}
                      </Button>
                    </Stack>
                  </div>
                );
              })}
            </Box>
          </>
        );
      }
    },
    {
      field: "Sunday",
      renderHeader: (params: any) => {
        return (
          <>
            <strong>{`Sunday ${WeekTableDays?.Sunday}`}</strong>
          </>
        );
      },
      sortable: false,
      width: 175,
      renderCell: (params: any) => {
        return (
          <>
            <Box
              sx={{ display: "flex", width: "100%", flexDirection: "column" }}
            >
              {params.row.Sunday.map((item, index) => {
                return (
                  <div key={index}>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{
                        width: "100%",
                        minWidth: "100%",
                        paddingBottom: 2
                      }}
                    >
                      <Button
                        title={item.ct_name}
                        variant="outlined"
                        color={item.is_temp === "n" ? "primary" : "warning"}
                        startIcon={<Person2Icon />}
                        style={{
                          width: "100%",
                          minWidth: "100%",
                          justifyContent: "left"
                        }}
                        onClick={() => {
                          getAppointmentClientView(item.id_calendar);
                          setOpenServiceView(true);
                        }}
                      >
                        {item.ct_name.length > 13
                          ? item.ct_name.substring(0, 10) + "..."
                          : item.ct_name}
                      </Button>
                    </Stack>
                  </div>
                );
              })}
            </Box>
          </>
        );
      }
    }
  ];
  const createCalendar = async () => {
    setGeneratingCalendar(true);
    const teste: boolean = await gerateScheduling();
    setGeneratingCalendar(false);
    getScheduling(dateInitReference);
    forceUpdate();
  };
  const getScheduling = async (d: Date) => {
    const r: CalendarEmployee[] = await getAllScheduling(d);

    setRows(r);
    forceUpdate();
    return r;
  };
  useEffect(() => {
    getEmployees();
    getCls();
  }, []);

  useEffect(() => {
    initDateInitialReference();
  }, [Employees]);

  useEffect(() => {
    setWeekDays();
  }, [dateInitReference]);

  const setWeekDays = () => {
    var d = new Date();
    var datetext = `${dateInitReference?.getFullYear()}-${
      dateInitReference?.getMonth() + 1
    }-${dateInitReference?.getDate()} 12:00`;
    d = new Date(datetext);
    
    var w: WeekTable = initWeekDays;
    if (d !== undefined) {
      w.Monday = moment(d).format("DD/MM/YYYY");
      d.setDate(d.getDate() + 1);
      w.Tuesday = moment(d).format("DD/MM/YYYY");
      d.setDate(d.getDate() + 1);
      w.Wednesday = moment(d).format("DD/MM/YYYY");
      d.setDate(d.getDate() + 1);
      w.Thursday = moment(d).format("DD/MM/YYYY");
      d.setDate(d.getDate() + 1);
      w.Friday = moment(d).format("DD/MM/YYYY");
      d.setDate(d.getDate() + 1);
      w.Saturday = moment(d).format("DD/MM/YYYY");
      d.setDate(d.getDate() + 1);
      w.Sunday = moment(d).format("DD/MM/YYYY");
      setWeekTableDays(w);

    }
  };
  const disableWeekends = (date: Date) => {
    var d = new Date(date.toString());
    return d.getDay() !== 1;
  };

  const disableDaysDateEdit = (date: Date) => {
    var d = new Date(date.toString());
    var dateC = new Date();
    dateC.setDate(dateC.getDate() - 1);
    return dateC >= d;
  };

  const initDateInitialReference = async () => {
    var d = new Date();
    if (d.getDay() == 0) {
      d.setDate(d.getDate() + 1);
    } else {
      while (d.getDay() > 1) {
        d.setDate(d.getDate() - 1);
      }
    }
    setDateInitReference(d);
    getScheduling(d);

    //setWeekDays(d)
    forceUpdate();
  };

  const handleChangeSelect = (event) => {
    const {
      target: { value }
    } = event;
    var e = Employees.find((x) => x.id === value);
    setIdProfessional(e);
  };

  const handleChangeSelectvView = (event) => {
    var appoin: AppointmentClient = AppointmentClientView;

    const {
      target: { value }
    } = event;

    var e = Employees.find((x) => x.id === value);
    appoin.id_professional = e.id;
    appoin.pr_email = e.email;
    appoin.is_temp =
      AppointmentClientView.id_professional ===
      AppointmentClientView.id_employee
        ? "n"
        : "s";
    setAppointmentClientView(appoin);
    forceUpdate();
  };

  const handleChangeSelectClient = (event) => {
    const {
      target: { value }
    } = event;
    var e = Clients.find((x) => x.id === value);
    setIdClient(e);
  };

  const handleChangeCheckbox = (checked: boolean) => {
    var appoin: AppointmentClient = AppointmentClientView;
    appoin.is_temp = checked ? "s" : "n";
    setAppointmentClientView(appoin);
    forceUpdate();
  };

  const handleChange = (event) => {
    switch (event.target.id) {
      case "start": {
        var value: inputText = {
          value: maskDate(event.target.value, DateStart.value),
          error: false
        };
        setDateStart(value);

        break;
      }
      case "payCash": {
        var v = formatMoney(event.target.value, payInCash);

        setPayInCash(v);

        break;
      }
      case "extraCost": {
        var v = formatMoney(event.target.value, extraCost);

        setExtraCost(v);

        break;
      }

      case "end": {
        var value: inputText = {
          value: maskDate(event.target.value, DateEnd.value),
          error: false
        };
        setDateEnd(value);

        break;
      }
    }
  };

  const validateFilds = () => {
    var isValid = false;

    weekNew.forEach((element) => {
      if (element.enabled) {
        isValid = true;
      }
    });
    if (IdProfessional.id == "") {
      setIdProfessional((emp) => {
        return { ...emp, error: true };
      });
      isValid = false;
    }
    if (IdClient.id == "") {
      setIdClient((emp) => {
        return { ...emp, error: true };
      });
      isValid = false;
    }
    if (DateStart.value === "" || !validateData(DateStart.value)) {
      setDateStart((emp) => {
        return { ...emp, error: true };
      });
      isValid = false;
    }
    if (DateEnd.value === "" || !validateData(DateStart.value)) {
      setDateEnd((emp) => {
        return { ...emp, error: true };
      });
      isValid = false;
    }

    return isValid;
  };

  const updateWeekNew = (id: number) => {
    var w: week = weekNew;
    w.forEach((element) => {
      if (element.dayId === id) element.enabled = !element.enabled;
    });
    setWeekNew(w);
    forceUpdate();
  };

  const saveAppointment = async () => {
    var appo: CalendarAppointment = {
      id_calendar: AppointmentClientView.id_calendar,
      hr_worked: AppointmentClientView.hr_worked,
      py_cash: AppointmentClientView.py_cash,
      ex_cost: AppointmentClientView.ex_cost,
      id_ot_professional: AppointmentClientView.id_professional,
      tp_date: AppointmentClientView.tp_date,
      is_temp: AppointmentClientView.is_temp,
      t_dy_client_id_client: AppointmentClientView.t_dy_client_id_client,
      hr_worked_divergent: null,
      py_cash_divergent: null,
      status: "pending"
    };
    var save = await updateAppointment(appo);
    setIsValid(save);
    setMessageAlert("Save success!");
    setTypeMessage("success");
    handleCloseServiceView();
    getScheduling(dateInitReference);
  };

  const appointmentDelete = async () => {
    setdeleteDialog(false);
    try {
      var del: boolean;

      del = await deleteAppointment(
        AppointmentClientView.id_calendar.toString()
      );
      setIsValid(del);
      setMessageAlert("Delete success!");
      setTypeMessage("success");
      handleCloseServiceView();
      getScheduling(dateInitReference);
    } catch (error) {
      setIsValid(true);
      setMessageAlert("Delete fail!");
      setTypeMessage("error");
    }
  };
  const saveAppointments = async () => {
    var validFilds = validateFilds();
    if (validFilds) {
      var dStart = DateStart.value.split("/");
      var dEnd = DateEnd.value.split("/");
      var save = await addApontments(
        parseInt(IdClient.id),
        parseInt(IdProfessional.id),
        weekNew,
        new Date(`${dStart[2]}-${dStart[1]}-${dStart[0]}`),
        new Date(`${dEnd[2]}-${dEnd[1]}-${dEnd[0]}`)
      );
      setIsValid(save);
      setMessageAlert("Save success!");
      setTypeMessage("success");
      setIsNew(false);
      setIsNewOrEdit(false);
      getEmployees();
      clearService();
      handleCloseServiceCreate();
    } else {
      setIsValid(true);
      setMessageAlert("Fill in the fields correctly!");
      setTypeMessage("error");
    }
  };
  return (
    <>
      <Navbar />
      <div className="calendar">
        <Loading
          loading={generatingCalendar}
          background="transparent"
          loaderColor="#3498db"
        />
        <div className="container_geral">
          <Snackbar
            open={isValid}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert severity={typeMessage} sx={{ width: "100%" }}>
              {messageAlert}
            </Alert>
          </Snackbar>
          {/* region modal addClient */}
          <Modal
            open={openServiceCreate}
            onClose={handleCloseServiceCreate}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ flexDirection: "row-reverse", display: "flex" }}>
                  <IconButton onClick={handleCloseServiceCreate}>
                    <AiIcons.AiOutlineClose />
                  </IconButton>
                </Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {`New Service Registration`}
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Item>
                      <div>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                          <InputLabel id="demo-multiple-name-label">
                            Client
                          </InputLabel>

                          <Select
                            error={IdClient.error}
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={IdClient.id}
                            onChange={handleChangeSelectClient}
                            input={<OutlinedInput label="Name" />}
                            MenuProps={MenuProps}
                          >
                            {Clients.map((emp) => (
                              <MenuItem
                                key={emp.id}
                                value={emp.id}
                                style={getStyles(emp.name, IdClient.id, theme)}
                              >
                                {emp.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <div>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                          <InputLabel id="demo-multiple-name-label">
                            Professional
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            error={IdProfessional.error}
                            value={IdProfessional.id}
                            onChange={handleChangeSelect}
                            input={<OutlinedInput label="Name" />}
                            MenuProps={MenuProps}
                          >
                            {Employees.map((emp) => (
                              <MenuItem
                                key={emp.id}
                                value={emp.id}
                                style={getStyles(
                                  emp.name,
                                  IdProfessional.id,
                                  theme
                                )}
                              >
                                {emp.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Item>
                      <div
                        style={{
                          margin: "auto",
                          top: "50%"
                        }}
                      >
                        <AiIcons.AiFillInfoCircle title="Required" size={15} />
                        <b style={{ paddingRight: 20, paddingBottom: 10 }}>
                          {" "}
                          Days Week
                        </b>
                      </div>
                      <div>
                        <br></br>
                        {weekNew.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="weekday"
                              title={item.dayName}
                              style={{
                                backgroundColor: !item.enabled
                                  ? "white"
                                  : "#000340"
                              }}
                            >
                              <div className="weekday2">
                                <button
                                  style={{
                                    backgroundColor: !item.enabled
                                      ? "white"
                                      : "#000340",
                                    border: 0
                                  }}
                                  onClick={(e) => {
                                    updateWeekNew(item.dayId);
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: 30,
                                      color: item.enabled ? "white" : "black"
                                    }}
                                  >
                                    <b>{item.day}</b>
                                  </p>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Item>
                      <div>
                        <TextField
                          style={{ width: "100%" }}
                          id="start"
                          label="Date Start"
                          variant="standard"
                          error={DateStart?.error}
                          value={DateStart?.value}
                          inputRef={inputRefDateStart}
                          onChange={handleChange}
                        />
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <div>
                        <TextField
                          style={{ width: "100%" }}
                          id="end"
                          label="Date End"
                          variant="standard"
                          error={DateEnd?.error}
                          value={DateEnd?.value}
                          inputRef={inputRefDateEnd}
                          onChange={handleChange}
                        />
                      </div>
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    container
                    xs={12}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Item>
                      <div>
                        <Button
                          variant="contained"
                          onClick={() => {
                            saveAppointments();
                          }}
                        >
                          Save
                        </Button>
                      </div>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Modal>
          {/* endregion */}
          {/* region modal ViewClient */}
          <Modal
            open={openServiceView}
            style={{ maxWidth: "100%" }}
            onClose={handleCloseServiceView}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ flexDirection: "row-reverse", display: "flex" }}>
                  <IconButton onClick={handleCloseServiceView}>
                    <AiIcons.AiOutlineClose />
                  </IconButton>
                </Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {`Service`}
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Item style={{ verticalAlign: "center" }}>
                      <div>
                        <p>
                          <b>{AppointmentClientView.ct_name} </b>
                        </p>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <Box>
                        <p>
                          <b>{`Status  `}</b>
                          {AppointmentClientView.is_temp == "n"
                            ? " Current"
                            : " Temporary"}
                        </p>
                      </Box>
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Item>
                      <div>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                          <InputLabel id="demo-multiple-name-label">
                            Professional
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={AppointmentClientView.id_professional}
                            onChange={handleChangeSelectvView}
                            input={<OutlinedInput label="Name" />}
                            MenuProps={MenuProps}
                          >
                            {Employees.map((emp) => (
                              <MenuItem
                                key={emp.id}
                                value={emp.id}
                                style={getStyles(emp.name, emp.id, theme)}
                              >
                                {emp.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={engb}
                      >
                        <DemoContainer components={["DatePicker"]}>
                          <DesktopDatePicker
                            inputRef={inputRefDateEdit}
                            defaultValue={new Date()}
                            value={AppointmentClientView.tp_date}
                            label="Date"
                            onChange={(newValue) => {
                              var app = AppointmentClientView;
                              app.tp_date = newValue;
                              setAppointmentClientView(app);
                              forceUpdate();
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {/* <Box style={{ paddingTop: 27 }}>
                        <p><b>{`Date  `}</b>{`${moment(AppointmentClientView.tp_date).format('DD/MM/YYYY')}`}
                        </p>
                      </Box> */}
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Item style={{ verticalAlign: "center" }}>
                      <Box>
                        <p>
                          <b>E-mail </b> {AppointmentClientView.pr_email}
                        </p>
                      </Box>
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Item>
                      <Box>
                        <p>
                          <b>{`Pay in cash  `} </b>
                          {`£${
                            AppointmentClientView.py_cash
                              ? AppointmentClientView.py_cash
                              : "0.00"
                          }`}
                        </p>
                      </Box>
                      {/* <div>
                        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                          <InputLabel htmlFor="standard-adornment-amount">Pay in cash</InputLabel>
                          <Input
                            id="payCash"
                            value={payInCash}
                            inputRef={inputRefPayInCash}
                            onChange={handleChange}
                            startAdornment={<InputAdornment position="start">£</InputAdornment>}
                          />
                        </FormControl>

                      </div> */}
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <Box>
                        <p>
                          <b>{`Extra Cost  `}</b>
                          {`£${
                            AppointmentClientView.ex_cost
                              ? AppointmentClientView.ex_cost
                              : "0.00"
                          }`}
                        </p>
                      </Box>
                      {/* <div>
                        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                          <InputLabel htmlFor="standard-adornment-amount">Extra Cost</InputLabel>
                          <Input
                            id="extraCost"
                            value={extraCost}
                            inputRef={inputRefExtraCost}
                            onChange={handleChange}
                            startAdornment={<InputAdornment position="start">£</InputAdornment>}
                          />
                        </FormControl>

                      </div> */}
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Item>
                      <Box>
                        <p>
                          <b>{`Payment method  `}</b>
                          {AppointmentClientView.dt_paymenttype
                            ? AppointmentClientView.dt_paymenttype[0].toUpperCase() +
                              AppointmentClientView.dt_paymenttype.substring(1)
                            : ""}
                        </p>
                      </Box>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <Box>
                        <p>
                          <b>{`Prevision hours  `}</b>
                          {`${AppointmentClientView.fr_hour} hour(s)`}
                        </p>
                      </Box>
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Item>
                      <Box>
                        <p>
                          <b>{`Total prevision  `}</b>
                          {`£ ${(
                            AppointmentClientView.vl_salary *
                            AppointmentClientView.fr_hour
                          ).toFixed(2)}`}
                        </p>
                      </Box>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <Box>
                        <p>
                          <b>{`Work hour(s)  `}</b>
                          {` ${
                            AppointmentClientView.hr_worked
                              ? AppointmentClientView.hr_worked
                              : "0:00"
                          }`}
                        </p>
                      </Box>
                      {/* <div>
                        <TextField
                          id="workHour"
                          label="Work hour(s)"
                          type="time"
                          defaultValue={AppointmentClientView.hr_worked ? AppointmentClientView.hr_worked : "0:00"}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300, // 5 min
                          }}
                          onChange={handleChange}
                          style={{ width: "100%" }}
                        />
                      </div> */}
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {AppointmentClientView.status != "completed" ? (
                    <Grid
                      item
                      container
                      xs={12}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Item>
                        <div>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                              setdeleteDialog(true);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </Item>
                      <Item>
                        <div>
                          <Button
                            variant="contained"
                            onClick={() => {
                              saveAppointment();
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      </Item>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Box>
            </Box>
          </Modal>
          <Dialog
            open={deleteDialog}
            onClose={() => setdeleteDialog(false)}
            onAccepted={() => appointmentDelete()}
          />
          {/* endregion */}
          <Logo />
          <div>
            <Box>
              <Grid container spacing={2} marginBottom="23px">
                {/* TODO: Adicionar dados dinâmicos */}
                <Grid item xs={4}>
                  <Item style={{ background: "rgba(0,0,0,0%)" }}>
                    <div>
                      <p style={{ fontSize: 20 }}>
                        Appointments today <b>{calendarStatus?.today || "-"}</b>
                      </p>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Item style={{ background: "rgba(0,0,0,0%)" }}>
                    <div>
                      <p style={{ fontSize: 20 }}>
                        Appointments in the week{" "}
                        <b>{calendarStatus?.week || "-"}</b>
                      </p>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Item style={{ background: "rgba(0,0,0,0%)" }}>
                    <div>
                      <p style={{ fontSize: 20 }}>
                        Appointments in the month{" "}
                        <b>{calendarStatus?.month || "-"}</b>
                      </p>
                    </div>
                  </Item>
                </Grid>
              </Grid>
              <Grid container spacing={2} marginBottom="23px">
                <Grid
                  item
                  xs={6}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Item style={{ background: "rgba(0,0,0,0%)" }}>
                    <div>
                      <Button
                        variant="outlined"
                        startIcon={<IoIcons.IoMdCalendar />}
                        onClick={createCalendar}
                      >
                        {`Generate schedule for the next 30 days`}
                      </Button>
                    </div>
                  </Item>
                </Grid>

                <Grid
                  item
                  xs={6}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Item style={{ background: "rgba(0,0,0,0%)" }}>
                    <div>
                      {/* TODO: Icon da tabela */}
                      <IconButton
                        color="primary"
                        component="label"
                        onClick={() => {
                          setIsNew(true);
                          setIsNewOrEdit(true);
                          setOpenServiceCreate(true);
                          clearService();
                        }}
                      >
                        <IoIcons.IoMdAddCircle />
                      </IconButton>
                    </div>
                  </Item>
                  <Item style={{ background: "rgba(0,0,0,0%)" }}>
                    <div>
                      <>
                        {dateInitReference ? (
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={engb}
                          >
                            <DemoContainer components={["DatePicker"]}>
                              <DesktopDatePicker
                                value={dateInitReference}
                                label="Date Reference"
                                shouldDisableDate={disableWeekends}
                                onChange={(newValue) => {
                                  setDateInitReference(newValue);
                                  getScheduling(newValue);
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        ) : null}
                      </>
                    </div>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </div>
          <DataGrid
            rowHeight={150}
            rows={rows}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
          />
        </div>
      </div>
    </>
  );
}

export default Calendar;
