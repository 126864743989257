import "../App.css";
import Navbar from "../components/Navbar";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Customers from "../pages/admin/customers";
import Employee from "../pages/admin/employee";
import PaymentCustomer from "../pages/admin/paymentCustomer";
import PaymentEmployeed from "../pages/admin/paymentEmployeed";
import Calendar from "../pages/admin/calendar";
import Divergences from "../pages/admin/divergences";
import useAuth from "../hooks/useAuth";
import Signin from "../pages/Signin";
import Signup from "../pages/Signup";
import { useState, Fragment } from "react";
import React, { useEffect, useReducer } from "react";
import Home from "../pages/admin/home";
import CheckList from "../pages/employee/checkList";

function RoutesApp() {
  const PrivateAdmin = ({ Item }) => {
    const navigate = useNavigate();
    const { user } = useAuth();
    if (user && user.userType == "admin") {
      return <Item />;
    } else {
      // navigate("/signin");
      return <Signin />;
    }
  };

  const PrivateEmployee = ({ Item }) => {
    const navigate = useNavigate();
    const { user } = useAuth();
    if (user && user.userType == "employee") {
      return <Item />;
    } else {
      // navigate("/signin");
      return <Signin />;
    }
  };

  const OpenWithoutLogin = ({ Item }) => {
    const { signed } = useAuth();
    return signed ? <Home /> : <Item />;
  };

  return (
    <>
      <Router>
        <Fragment>
          <Routes>
            <Route path="/home" element={<PrivateAdmin Item={Home} />} />
            <Route path="/" element={<OpenWithoutLogin Item={Signin} />} />
            <Route
              path="/customers"
              element={<PrivateAdmin Item={Customers} />}
            />
            <Route
              path="/employee"
              element={<PrivateAdmin Item={Employee} />}
            />
            <Route
              path="/paymentCustomers"
              element={<PrivateAdmin Item={PaymentCustomer} />}
            />
            <Route
              path="/paymentEmployeed"
              element={<PrivateAdmin Item={PaymentEmployeed} />}
            />
            <Route
              path="/calendar"
              element={<PrivateAdmin Item={Calendar} />}
            />
            <Route
              path="/divergences"
              element={<PrivateAdmin Item={Divergences} />}
            />

            <Route
              path="/checkList"
              element={<PrivateEmployee Item={CheckList} />}
            />
            <Route path="*" element={<Signin />} />
          </Routes>
        </Fragment>
      </Router>
    </>
  );
}

export default RoutesApp;
