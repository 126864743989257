import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as ImIcons from "react-icons/im";
import { MdRemoveDone } from "react-icons/md";

export const SidebarData = [
  {
    title: "Home",
    path: "/home",
    icon: <AiIcons.AiFillHome />,
    cName: "nav-text"
  },
  {
    title: "Customers",
    path: "/customers",
    icon: <ImIcons.ImUsers />,
    cName: "nav-text"
  },
  {
    title: "Employees",
    path: "/employee",
    icon: <ImIcons.ImUser />,
    cName: "nav-text"
  },
  {
    title: "Divergences",
    path: "/divergences",
    icon: <MdRemoveDone />,
    cName: "nav-text"
  },
  {
    title: "Payment Customer",
    path: "/paymentCustomers",
    icon: <FaIcons.FaDollarSign />,
    cName: "nav-text"
  },
  {
    title: "Payment Employee",
    path: "/paymentEmployeed",
    icon: <FaIcons.FaDollarSign />,
    cName: "nav-text"
  },
  {
    title: "Calendar",
    path: "/calendar",
    icon: <FaIcons.FaCalendarAlt />,
    cName: "nav-text"
  }
];
