import React, { useEffect, useReducer } from "react";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridApi,
  GridCellValue
} from "@mui/x-data-grid";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  styled,
  TextField,
  Typography
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Client } from "../../interfaces/Cliente";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";
import * as IoIcons from "react-icons/io";
import { alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { sizeWidth } from "@mui/system";
import {
  deleteClient,
  getClient,
  getClients,
  rowClient,
  rowsClient,
  saveClient,
  updateClient
} from "../../models/clients";
import { ContactPhone, Key, RowingSharp } from "@mui/icons-material";
import { getWeek, week, day } from "../../models/week";
import Logo from "../../components/icons/Logo";
import {
  formatMoney,
  formatPhone,
  maskDate,
  validateData,
  validateEmail,
  validateMoney
} from "../../utils/utils";
import { getAllEmployees, rowsEmployee } from "../../models/employee";
import { useTheme } from "@mui/material/styles";
import moment, { weekdays } from "moment";
import Slide from "@mui/material/Slide";
import ViewCustomer from "../../components/ViewCustomer";
import Navbar from "../../components/Navbar";
import { parse } from "date-fns";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(name, id, theme) {
  return {
    fontWeight: theme.typography.fontWeightRegular
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxWidth: "100%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "100%",
  overflow: "scroll"
};

const initRows: rowsClient = [];
const initCustomer: Client = {
  id: 0,
  name: "",
  nameOfContact: "",
  email: "",
  phoneNumber: "",
  addressLine: "",
  paymentPerHour: 0,
  paymentFrequency: "",
  servicesFrequency: "",
  servicesExpectedHours: "",
  servicesByDayOfWeek: "",
  customerSince: new Date(),
  dateRefForSchedules: new Date(),
  paymentType: "",
  additionalInfo: "",
  servicesFromCompany: "",
  id_employee: 0,
  active: true
};

type inputText = {
  value: string;
  error: boolean;
};

type inputProfecional = {
  name: string;
  id: string;
};

type Severity = "error" | "success" | "info" | "warning" | undefined;
type Services = "dynamos " | "master" | "clean" | "bright " | undefined;
const initRowsEmployes: rowsEmployee = [];
function Customers() {
  const theme = useTheme();
  const [Employees, setEmployees] = React.useState([]);
  const [Customer, setCustomer] = React.useState<Client>(initCustomer);
  const [CustomerId, setCustomerId] = React.useState(0);
  const [CustomerName, setCustomerName] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [CustomerContact, setCustomerContact] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [CustomerEmail, setCustomerEmail] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [CustomerPhone, setCustomerPhone] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [CustomerAddress, setCustomerAddress] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [CustomerVlHour, setCustomerVlHour] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [CustomerPdFrequency, setCustomerPdFrequency] =
    React.useState<inputText>({ value: "weekly", error: false });
  const [CustomerDays, setCustomerDays] = React.useState<inputText>({
    value: "weekly",
    error: false
  });
  const [CustomerFrHour, setCustomerFrHours] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [CustomerDyFrequency, setCustomerDyFrequency] =
    React.useState<inputText>({ value: "", error: false });
  const [CustomerSince, setCustomerSince] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [DateRefForSchedule, setDateRefForSchedule] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [CustomerPaymenttype, setCustomerPaymenttype] =
    React.useState<inputText>({ value: "cash", error: false });
  const [CustomerComment, setCustomerComment] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [CustomerIdProfessional, setCustomerIdProfessional] =
    React.useState<inputProfecional>({ name: "", id: "" });
  const [CustomerServices, setCustomerServices] = React.useState({
    name: "",
    id: ""
  });
  const [CustomerActive, setCustomerActive] = React.useState<boolean>(true);

  const inputRefName = React.useRef<HTMLInputElement>();
  const inputRefContact = React.useRef<HTMLInputElement>();
  const inputRefEmail = React.useRef<HTMLInputElement>();
  const inputRefPhone = React.useRef<HTMLInputElement>();
  const inputRefAddress = React.useRef<HTMLInputElement>();
  const inputRefVlHour = React.useRef<HTMLInputElement>();
  const inputRefPdFrequency = React.useRef<HTMLInputElement>();
  const inputRefDays = React.useRef<HTMLInputElement>();
  const inputRefFrHour = React.useRef<HTMLInputElement>();
  const inputRefDyFrequency = React.useRef<HTMLInputElement>();
  const inputRefSince = React.useRef<HTMLInputElement>();
  const inputDateRefSince = React.useRef<HTMLInputElement>();
  const inputRefPaymenttype = React.useRef<HTMLInputElement>();
  const inputRefComment = React.useRef<HTMLInputElement>();
  const inputRefIdProfessional = React.useRef<HTMLInputElement>();

  const [isNew, setIsNew] = React.useState(true);
  const [isValid, setIsValid] = React.useState(false);
  const [isNewOrEdit, setIsNewOrEdit] = React.useState<boolean>(false);

  const [openClientInfo, setOpenClientInfo] = React.useState(false);
  const handleOpenClientInfo = () => setOpenClientInfo(true);
  const handleCloseClientInfo = () => setOpenClientInfo(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [openClientCreate, setOpenClientCreate] = React.useState(false);
  const handleOpenClientCreate = () => setOpenClientCreate(true);
  const handleCloseClientCreate = () => setOpenClientCreate(false);

  const [openClientEdit, setOpenClientEdit] = React.useState(false);
  const handleOpenClientEdit = () => setOpenClientEdit(true);
  const handleCloseClientEdit = () => setOpenClientEdit(false);

  const [rows, setRows] = React.useState<rowsClient>(initRows);
  const [countClients, setCountClients] = React.useState(0);
  const [sizeScreen, setSizeScreen] = React.useState({
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight
  });

  const [FixEmployeed, setFixEmployeed] = React.useState("");

  const [weekNew, setWeekNew] = React.useState(getWeek());
  const [weekView, setWeekView] = React.useState(getWeek());

  const [messageAlert, setMessageAlert] = React.useState("");
  const [typeMessage, setTypeMessage] = React.useState<Severity>("success");

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseOpen = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    inputRefName.current?.focus();
  }, [CustomerName]);
  React.useEffect(() => {
    inputRefContact.current?.focus();
  }, [CustomerContact]);
  React.useEffect(() => {
    inputRefEmail.current?.focus();
  }, [CustomerEmail]);
  React.useEffect(() => {
    inputRefPhone.current?.focus();
  }, [CustomerPhone]);
  React.useEffect(() => {
    inputRefAddress.current?.focus();
  }, [CustomerAddress]);
  React.useEffect(() => {
    inputRefVlHour.current?.focus();
  }, [CustomerVlHour]);
  React.useEffect(() => {
    inputRefPdFrequency.current?.focus();
  }, [CustomerPdFrequency]);
  React.useEffect(() => {
    inputRefDays.current?.focus();
  }, [CustomerDays]);
  React.useEffect(() => {
    inputRefFrHour.current?.focus();
  }, [CustomerFrHour]);
  React.useEffect(() => {
    inputRefDyFrequency.current?.focus();
  }, [CustomerDyFrequency]);
  React.useEffect(() => {
    inputRefSince.current?.focus();
  }, [CustomerSince]);
  React.useEffect(() => {
    inputDateRefSince.current?.focus();
  }, [DateRefForSchedule]);
  React.useEffect(() => {
    inputRefPaymenttype.current?.focus();
  }, [CustomerPaymenttype]);
  React.useEffect(() => {
    inputRefComment.current?.focus();
  }, [CustomerComment]);
  React.useEffect(() => {
    inputRefIdProfessional.current?.focus();
  }, [CustomerIdProfessional]);

  const clearCustomer = () => {
    setCustomerName({ value: "", error: false });
    setCustomerContact({ value: "", error: false });
    setCustomerEmail({ value: "", error: false });
    setCustomerPhone({ value: "", error: false });
    setCustomerAddress({ value: "", error: false });
    setCustomerVlHour({ value: "", error: false });
    setCustomerPdFrequency({ value: "weekly", error: false });
    setCustomerDays({ value: "week", error: false });
    setCustomerFrHours({ value: "", error: false });
    setCustomerDyFrequency({ value: "", error: false });
    setCustomerSince({ value: "", error: false });
    setCustomerPaymenttype({ value: "cash", error: false });
    setCustomerComment({ value: "", error: false });
    setCustomerIdProfessional({ name: "", id: "" });
    setCustomerServices({ name: "", id: "" });
    setCustomerActive(false);
    var w = weekNew;
    weekNew.forEach((element) => {
      element.enabled = false;
    });
    setWeekNew(w);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsValid(false);
  };

  const handleChange = (event) => {
    
    switch (event.target.id) {
      case "Name":
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setCustomerName(value);
        break;
      case "Contact":
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setCustomerContact(value);
        break;
      case "Email":
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setCustomerEmail(value);
        break;
      case "Phone":
        var value: inputText = {
          value: formatPhone(event.target.value, CustomerPhone.value),
          error: event.target.value.length === 0
        };
        setCustomerPhone(value);
        break;
      case "Address":
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setCustomerAddress(value);
        break;
      case "VlHour": {
        var value: inputText = {
          value: formatMoney(event.target.value, CustomerVlHour.value),
          error: event.target.value.length === 0
        };
        setCustomerVlHour(value);

        break;
      }
      case "PdFrequency": {
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setCustomerPdFrequency(value);
        break;
      }
      case "Days": {
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setCustomerDays(value);
        break;
      }
      case "FrHour": {
        const regex = /^[0-9\b]+$/;
        if (event.target.value === "" || regex.test(event.target.value)) {
          var value: inputText = {
            value: event.target.value,
            error: event.target.value.length === 0
          };
          setCustomerFrHours(value);
        }
        break;
      }
      case "DyFrequency": {
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setCustomerDyFrequency(value);

        break;
      }
      case "Since": {
        var value: inputText = {
          value: maskDate(event.target.value, CustomerSince.value),
          error: event.target.value.length === 0
        };
        setCustomerSince(value);
        break;
      }
      case "DateRef": {
        var value: inputText = {
          value: maskDate(event.target.value, DateRefForSchedule.value),
          error: event.target.value.length === 0
        };
        setDateRefForSchedule(value);
        break;
      }
      case "Paymenttype": {
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setCustomerPaymenttype(value);

        break;
      }
      case "Comment": {
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setCustomerComment(value);

        break;
      }
    }
    switch (event.target.name) {
      case "Payday":
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setCustomerPdFrequency(value);
        break;

      case "Days":
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setCustomerDays(value);
        break;
      case "Paymenttype":
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setCustomerPaymenttype(value);
        break;
    }
    // console.log(event.target)
    setTimeout(() => event.target.focus(), 200)
  };
  const handleChangeSelect = (event) => {
    const {
      target: { value }
    } = event;
    var e = Employees.find((x) => x.id === value);
    setCustomerIdProfessional(e);
  };

  const handleChangeSelectService = (event) => {
    var s = { name: "", id: "" };
    switch (event.target.value) {
      case "dynamos":
        s.id = event.target.value;
        s.name = "Dynamos Cleaning Services Ltd";
        break;
      case "master":
        s.id = event.target.value;
        s.name = "Master Plus Cleaning Services Ltd";
        break;
      case "clean":
        s.id = event.target.value;
        s.name = "Clean Plus Cleaning Services Ltd";
        break;
      case "bright":
        s.id = event.target.value;
        s.name = "Bright Touch Cleaning Services Ltd";
        break;
    }
    setCustomerServices(s);
  };

  const getEmployees = async () => {
    var e = [];
    const emplos: rowsEmployee = await getAllEmployees();
    emplos.forEach((element) => {
      var user = {
        name: element.Name,
        id: element.id
      };
      e.push(user);
    });
    setEmployees(e);
    forceUpdate();
  };

  const customerView = async (id) => {
    clearCustomer();
    const e: Client = await getClient(id);
    var s = "";
    switch (e.servicesFromCompany) {
      case "dynamos":
        s = "Dynamos Cleaning Services Ltd";
        break;
      case "master":
        s = "Master Plus Cleaning Services Ltd";
        break;
      case "clean":
        s = "Clean Plus Cleaning Services Ltd";
        break;
      case "bright":
        s = "Bright Touch Cleaning Services Ltd";
        break;
    }
    e.servicesFromCompany = s;
    var w = getWeek();
    var days = e.servicesByDayOfWeek.split(",");
    days.forEach((element) => {
      w.forEach((d) => {
        if (element.trim().toLowerCase() === d.dayName.toLowerCase()) d.enabled = true;
      });
    });
    setCustomerId(id);
    setWeekView(w);
    setCustomer(e);
    setOpenClientInfo(true);
    forceUpdate();
  };

  const customerDelete = async () => {
    try {
      var del: boolean;

      del = await deleteClient(CustomerId.toString());
      setIsValid(del);
      setMessageAlert("Delete success!");
      setTypeMessage("success");
      handleCloseOpen();
      getCustomers();
      forceUpdate();
    } catch (error) {
      setIsValid(true);
      setMessageAlert("Delete fail!");
      setTypeMessage("error");
    }
  };

  const customerEdit = async (id) => {
    clearCustomer();
    const c: Client = await getClient(id);
    setCustomerName({ value: c.name ?? "", error: false });
    setCustomerContact({ value: c.nameOfContact ?? "", error: false });
    setCustomerEmail({ value: c.email ?? "", error: false });
    setCustomerPhone({ value: c.phoneNumber ?? "", error: false });
    setCustomerAddress({ value: c.addressLine ?? "", error: false });
    setCustomerVlHour({
      value: parseFloat(c.paymentPerHour.toString()).toFixed(2) || "0.0",
      error: false
    });
    setCustomerPdFrequency({
      value:
        c.paymentFrequency == "" ||
        c.paymentFrequency == "0" ||
        c.paymentFrequency == null
          ? "weekly"
          : c.paymentFrequency.toLowerCase(),
      error: false
    });
    setCustomerDays({
      value:
        c.servicesFrequency == "" ||
        c.servicesFrequency == "0" ||
        c.servicesFrequency == null
          ? "weekly"
          : c.servicesFrequency.toLowerCase(),
      error: false
    });
    setCustomerFrHours({ value: c.servicesExpectedHours ?? "", error: false });
    setCustomerDyFrequency({
      value: c.servicesByDayOfWeek ?? "",
      error: false
    });
    setCustomerSince({
      value: c.customerSince
        ? moment(c.customerSince).format("DD/MM/YYYY")
        : "",
      error: false
    });
    setDateRefForSchedule({
      value: c.dateRefForSchedules
        ? moment(c.dateRefForSchedules).format("DD/MM/YYYY")
        : "",
      error: false
    });
    setCustomerPaymenttype({
      value:
        c.paymentType == "" || c.paymentType == "0" || c.paymentType == null
          ? "cash"
          : c.paymentType.toLowerCase(),
      error: false
    });
    setCustomerComment({ value: c.additionalInfo ?? "", error: false });
    setCustomerIdProfessional({
      name: c.employeeName ? c.employeeName : "",
      id: c.id_employee ? c.id_employee.toString() : ""
    });
    setCustomerActive(c.active);
    var s = { name: "", id: "" };
    s.id = c.servicesFromCompany ?? "";
    switch (c.servicesFromCompany) {
      case "dynamos":
        s.name = "Dynamos Cleaning Services Ltd";
        break;
      case "master":
        s.name = "Master Plus Cleaning Services Ltd";
        break;
      case "clean":
        s.name = "Clean Plus Cleaning Services Ltd";
        break;
      case "bright":
        s.name = "Bright Touch Cleaning Services Ltd";
        break;
    }
    setCustomerServices(s);
    var w = weekNew;
    var days = c.servicesByDayOfWeek.split(",");
    days.forEach((element) => {
      w.forEach((d) => {
        if (element.trim().toLocaleLowerCase() === d.dayName.toLowerCase())
          d.enabled = true;
      });
    });
    setCustomerId(id);
    setWeekNew(w);
    setOpenClientCreate(true);
    setIsNew(false);
    setIsNewOrEdit(true);
    //setCustomer(c)

    forceUpdate();
  };
  const inputRef = React.useRef<HTMLInputElement>();

  const getCustomers = async () => {
    var r: rowsClient = [];
    const teste: rowsClient = await getClients();
    setRows(teste);
    forceUpdate();
    return r;
  };

  const getCustomer = async (id) => {
    const c: Client = await getClient(id);
    setCustomer(c);
    setOpenClientEdit(true);
    forceUpdate();
    return c;
  };

  // useEffect(() => {
  //   console.log(sizeScreen);
  // }, [sizeScreen]);

  useEffect(() => {
    getCustomers();
    getEmployees();
  }, []);

  const addRow = () => {
    var oldRows = rows;
    var newId = Math.max(...oldRows.map((o) => o.id));
    var newRow: rowClient = {
      id: newId,
      company: "IT Trevo",
      address: "Via de Ca...",
      services: "Dynamos",
      phone: "+55 (35) 921213213",
      status: "Debit"
    };
    rows.push(newRow);
    setRows(oldRows);
  };

  React.useEffect(() => {
    inputRef.current?.focus();
  }, [Customer]);

  useEffect(() => {
    setCountClients(rows.length);
  }, [rows, weekNew]);

  useEffect(() => {
    var w: week = weekNew;
    setWeekNew(w);
  }, [weekNew]);

  const updateWeekNew = (id: number) => {
    var w: week = weekNew;
    w.forEach((element) => {
      if (element.dayId === id) element.enabled = !element.enabled;
    });
    setWeekNew(w);
    forceUpdate();
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "company", headerName: "Company", width: 210 },
    { field: "address", headerName: "Address", width: 210 },
    { field: "services", headerName: "Services", width: 210 },
    { field: "phone", headerName: "Phone", width: 190 },
    { field: "active", renderCell: (params: any) => <>{params.row.active?"Active":"Inative"}</>, headerName: "Status", width: 60 },

    // { field: 'status', headerName: 'Status', width: 90 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params: any) => {
        return (
          <>
            <div>
              {/* <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={() => {
                  clearCustomer();
                  handleClickOpen();
                  setCustomerId(params.id);
                  // customerDelete(params.id)
                }}
              >
                <AiIcons.AiFillDelete />
              </IconButton> */}
              <IconButton
                color="primary"
                component="label"
                onClick={() => customerEdit(params.id)}
              >
                <AiIcons.AiFillEdit />
              </IconButton>
              <IconButton
                color="primary"
                component="label"
                onClick={() => customerView(params.id)}
              >
                <AiIcons.AiFillInfoCircle />
              </IconButton>
            </div>
          </>
        );
      }
    }
  ];

  const validateFilds = () => {
    var isValid = true;
    if (CustomerName.value === "") {
      setCustomerName((cust) => {
        return { ...cust, error: true };
      });
      isValid = false;
    }
    if (CustomerEmail.value === "" || !validateEmail(CustomerEmail.value)) {
      setCustomerEmail((cust) => {
        return { ...cust, error: true };
      });
      isValid = false;
    }
    if (CustomerAddress.value === "") {
      setCustomerAddress((cust) => {
        return { ...cust, error: true };
      });
      isValid = false;
    }
    if (CustomerContact.value === "") {
      setCustomerContact((cust) => {
        return { ...cust, error: true };
      });
      isValid = false;
    }
    if (CustomerPhone.value === "" || CustomerPhone.value.length < 11) {
      setCustomerPhone((cust) => {
        return { ...cust, error: true };
      });
      isValid = false;
    }
    if (CustomerFrHour.value === "") {
      setCustomerFrHours((cust) => {
        return { ...cust, error: true };
      });
      isValid = false;
    }
    if (CustomerPdFrequency.value === "") {
      setCustomerPdFrequency((cust) => {
        return { ...cust, error: true };
      });
      isValid = false;
    }
    if (CustomerDays.value === "") {
      setCustomerDays((cust) => {
        return { ...cust, error: true };
      });
      isValid = false;
    }
    if (CustomerSince.value === "" || !validateData(CustomerSince.value)) {
      setCustomerSince((cust) => {
        return { ...cust, error: true };
      });
      isValid = false;
    }

    if (DateRefForSchedule.value === "" || !validateData(DateRefForSchedule.value)) {
      setDateRefForSchedule((cust) => {
        return { ...cust, error: true };
      });
      isValid = false;
    }

    if (CustomerVlHour.value === "" || !validateMoney(CustomerVlHour.value)) {
      setCustomerVlHour((cust) => {
        return { ...cust, error: true };
      });
      isValid = false;
    }
    return isValid;
  };
  const saveCustomer = async () => {
    var validFilds = validateFilds();

    if (validFilds) {
      var frequency = weekNew
        .filter((w) => w.enabled)
        .map((w) => w.dayName.toLocaleLowerCase())
        .join(",");
      var cust: Client = {
        id: CustomerId,
        name: CustomerName.value,
        nameOfContact: CustomerContact.value,
        email: CustomerEmail.value,
        phoneNumber: CustomerPhone.value,
        addressLine: CustomerAddress.value,
        paymentPerHour: parseFloat(CustomerVlHour.value),
        paymentFrequency: CustomerPdFrequency.value,
        servicesFrequency: CustomerDays.value,
        servicesExpectedHours: CustomerFrHour.value,
        servicesByDayOfWeek: frequency,
        customerSince: parse(CustomerSince.value, "dd/MM/yyyy", new Date()),
        dateRefForSchedules: parse(DateRefForSchedule.value, "dd/MM/yyyy", new Date()),
        paymentType: CustomerPaymenttype.value,
        additionalInfo: CustomerComment.value,
        servicesFromCompany: CustomerServices.id,
        id_employee: parseInt(CustomerIdProfessional.id),
        active: CustomerActive
      };
      try {
        var save: boolean;
        if (isNew) save = await saveClient(cust);
        else {
          save = await updateClient(cust);
        }
        setOpenClientCreate(false);
        setIsValid(save);
        setMessageAlert("Save success!");
        setTypeMessage("success");
        setIsNew(false);
        setIsNewOrEdit(false);
        getCustomers();
        clearCustomer();
        forceUpdate();
      } catch (error) {
        setIsValid(true);
        setMessageAlert("Save error!");
        setTypeMessage("error");
      }
    } else {
      setIsValid(true);
      setMessageAlert("Fill in the fields correctly!");
      setTypeMessage("error");
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
    border: theme.spacing(10),
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);"
  }));
  return (
    <>
      <Navbar />
      <div className="customers">
        <div className="container_geral">
          <div>
            <Dialog
              open={open}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Delete customer?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Confirm to delete the customer
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseOpen}>Cancel</Button>
                <Button onClick={() => customerDelete()}>Confirm</Button>
              </DialogActions>
            </Dialog>
          </div>
          <Snackbar
            open={isValid}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert severity={typeMessage} sx={{ width: "100%" }}>
              {messageAlert}
            </Alert>
          </Snackbar>
          <Logo />

          <div>
            {/* region modal infoClient */}
            <Modal
              open={openClientInfo}
              onClose={handleCloseClientInfo}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Box sx={{ flexGrow: 1 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ flexDirection: "row-reverse", display: "flex" }}>
                      <IconButton onClick={handleCloseClientInfo}>
                        <AiIcons.AiOutlineClose />
                      </IconButton>
                    </Box>
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    boxShadow="0px 2px 0px 0px rgb(0 0 0 / 20%),0px 2px 0px 0px rgb(0 0 0 / 20%)"
                    marginBottom="23px"
                  >
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <p>
                            <b>{Customer.name} </b>
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Contact Name </b>
                            {Customer.nameOfContact}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>E-Mail </b>
                            {Customer.email}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Address </b>
                            {Customer.addressLine}
                          </p>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <p>
                            <b>Phone Number </b>
                            {Customer.phoneNumber}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Prevision hours </b>
                            {`${Customer.servicesExpectedHours} Hours/service`}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Customer since </b>
                            <>
                              {moment(Customer.customerSince).format(
                                "DD/MM/YYYY"
                              )}
                            </>
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Date Ref For Schedules</b>
                            <>
                              {moment(Customer.dateRefForSchedules).format(
                                "DD/MM/YYYY"
                              )}
                            </>
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Status </b>
                            <>{Customer.active ? "Active" : "Inactive"}</>
                          </p>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <p>
                            <b>Payment </b>
                            {`${Customer.paymentPerHour} £/h`}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Fix Employeed </b>
                            {Customer.employeeName}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Frequency </b>
                            {Customer.servicesFrequency}
                          </p>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <p>
                            <b>Payment method </b>
                            {Customer.paymentType}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Payday </b>
                            {Customer.paymentFrequency}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Service </b>
                            {Customer.servicesFromCompany}
                          </p>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Item>
                        <div style={{ paddingBottom: 10 }}>
                          <b style={{ paddingRight: 20, paddingBottom: 10 }}>
                            Days Week{" "}
                          </b>
                        </div>
                        <div>
                          {weekView.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="weekday"
                                title={item.dayName}
                                style={{
                                  backgroundColor: !item.enabled
                                    ? "white"
                                    : "#000340"
                                }}
                              >
                                <div className="weekday2">
                                  <button
                                    style={{
                                      backgroundColor: !item.enabled
                                        ? "white"
                                        : "#000340",
                                      border: 0
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 30,
                                        color: item.enabled ? "white" : "black"
                                      }}
                                    >
                                      <b>{item.day}</b>
                                    </p>
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Item>
                        <div>
                          <p>
                            <b>Observation </b>
                            {Customer.additionalInfo || "-"}
                          </p>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Modal>
            {/* endregion */}
            {/* region modal addClient */}
            <Modal
              style={{ maxWidth: "100%" }}
              open={openClientCreate}
              onClose={handleCloseClientCreate}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Box sx={{ flexGrow: 1 }}>
                  <Box sx={{ flexDirection: "row-reverse", display: "flex" }}>
                    <IconButton onClick={handleCloseClientCreate}>
                      <AiIcons.AiOutlineClose />
                    </IconButton>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {`${isNew ? "New" : "Edit"} Customer Registration`}
                  </Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            id="Name"
                            label="Complete Name"
                            variant="standard"
                            error={CustomerName?.error}
                            value={CustomerName?.value}
                            inputRef={inputRefName}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            id="Contact"
                            label="Contact Name"
                            variant="standard"
                            error={CustomerContact?.error}
                            value={CustomerContact?.value}
                            inputRef={inputRefContact}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            size="medium"
                            id="Address"
                            label="Address"
                            variant="standard"
                            error={CustomerAddress?.error}
                            value={CustomerAddress.value}
                            inputRef={inputRefAddress}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            size="medium"
                            id="Email"
                            label="Email"
                            variant="standard"
                            error={CustomerEmail?.error}
                            value={CustomerEmail.value}
                            inputRef={inputRefEmail}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            size="medium"
                            id="Phone"
                            label="Phone Number"
                            variant="standard"
                            error={CustomerPhone?.error}
                            value={CustomerPhone.value}
                            inputRef={inputRefPhone}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            size="medium"
                            id="FrHour"
                            label="Prevision Hour"
                            variant="standard"
                            error={CustomerFrHour?.error}
                            value={CustomerFrHour.value}
                            inputRef={inputRefFrHour}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Payday
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="Payday"
                              id="Payday"
                              value={CustomerPdFrequency.value}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="weekly"
                                control={<Radio />}
                                label="Weekly"
                              />
                              <FormControlLabel
                                value="biweekly"
                                control={<Radio />}
                                label="Biweekly"
                              />
                              <FormControlLabel
                                value="monthly"
                                control={<Radio />}
                                label="Monthly"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Frequency of days
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="Days"
                              id="Days"
                              value={CustomerDays.value}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="weekly"
                                control={<Radio />}
                                label="Weekly"
                              />
                              <FormControlLabel
                                value="biweekly"
                                control={<Radio />}
                                label="Biweekly"
                              />
                              <FormControlLabel
                                value="monthly"
                                control={<Radio />}
                                label="Monthly"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            size="medium"
                            id="Since"
                            label="Customer since"
                            variant="standard"
                            error={CustomerSince?.error}
                            value={CustomerSince.value}
                            ref={inputRefSince}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <TextField
                            style={{ width: "100%" }}
                            size="medium"
                            id="DateRef"
                            label="Date Ref for Schedules"
                            variant="standard"
                            error={DateRefForSchedule?.error}
                            value={DateRefForSchedule.value}
                            ref={inputDateRefSince}
                            onChange={handleChange}
                          />
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-multiple-name-label">
                          Active
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          value={CustomerActive ? "active" : "inactive"}
                          onChange={(event) => {
                            setCustomerActive(event.target.value === "active");
                          }}
                          input={<OutlinedInput label="active" />}
                        >
                          <MenuItem key={"active"} value={"active"}>
                            active
                          </MenuItem>
                          <MenuItem key={"inactive"} value={"inactive"}>
                            inactive
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Item>
                        <div style={{ paddingBottom: 10 }}>
                          <b style={{ paddingRight: 20, paddingBottom: 10 }}>
                            Days Week{" "}
                          </b>
                        </div>
                        <div>
                          {weekNew.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="weekday"
                                title={item.dayName}
                                style={{
                                  backgroundColor: !item.enabled
                                    ? "white"
                                    : "#000340"
                                }}
                              >
                                <div className="weekday2">
                                  <button
                                    style={{
                                      backgroundColor: !item.enabled
                                        ? "white"
                                        : "#000340",
                                      border: 0
                                    }}
                                    onClick={(e) => {
                                      updateWeekNew(item.dayId);
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 30,
                                        color: item.enabled ? "white" : "black"
                                      }}
                                    >
                                      <b>{item.day}</b>
                                    </p>
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                            variant="standard"
                          >
                            <InputLabel htmlFor="standard-adornment-amount">
                              Value per hour
                            </InputLabel>
                            <Input
                              id="VlHour"
                              error={CustomerVlHour.error}
                              value={CustomerVlHour.value}
                              inputRef={inputRefVlHour}
                              onChange={handleChange}
                              startAdornment={
                                <InputAdornment position="start">
                                  £
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Type Payment
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="Paymenttype"
                              id="Paymenttype"
                              value={CustomerPaymenttype.value}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="cash"
                                control={<Radio />}
                                label="Cash"
                              />
                              <FormControlLabel
                                value="bank"
                                control={<Radio />}
                                label="Bank"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <FormControl sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="demo-multiple-name-label">
                              Professional
                            </InputLabel>
                            <Select
                              labelId="demo-multiple-name-label"
                              id="demo-multiple-name"
                              value={CustomerIdProfessional.id}
                              onChange={handleChangeSelect}
                              input={<OutlinedInput label="Name" />}
                              MenuProps={MenuProps}
                            >
                              {Employees.map((emp) => (
                                <MenuItem
                                  key={emp.id}
                                  value={emp.id}
                                  style={getStyles(
                                    emp.name,
                                    CustomerIdProfessional.id,
                                    theme
                                  )}
                                >
                                  {emp.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <div>
                          <FormControl sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="demo-multiple-name-label">
                              Service
                            </InputLabel>
                            <Select
                              labelId="demo-multiple-name-label"
                              id="demo-multiple-name"
                              value={CustomerServices.id}
                              name={CustomerServices.name}
                              onChange={handleChangeSelectService}
                              input={<OutlinedInput label="Name" />}
                              MenuProps={MenuProps}
                            >
                              <MenuItem
                                key={"dynamos"}
                                value={"dynamos"}
                                style={getStyles(
                                  "Dynamos Cleaning Services Ltd",
                                  CustomerServices.id,
                                  theme
                                )}
                              >
                                {" "}
                                {"Dynamos Cleaning Services Ltd"}
                              </MenuItem>
                              <MenuItem
                                key={"master"}
                                value={"master"}
                                style={getStyles(
                                  "Master Plus Cleaning Services Ltd",
                                  CustomerServices.id,
                                  theme
                                )}
                              >
                                {" "}
                                {"Master Plus Cleaning Services Ltd"}
                              </MenuItem>
                              <MenuItem
                                key={"clean"}
                                value={"clean"}
                                style={getStyles(
                                  "Clean Plus Cleaning Services Ltd",
                                  CustomerServices.id,
                                  theme
                                )}
                              >
                                {" "}
                                {"Clean Plus Cleaning Services Ltd"}
                              </MenuItem>
                              <MenuItem
                                key={"bright"}
                                value={"bright"}
                                style={getStyles(
                                  "Bright Touch Cleaning Services Ltd",
                                  CustomerServices.id,
                                  theme
                                )}
                              >
                                {" "}
                                {"Bright Touch Cleaning Services Ltd"}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <>
                      {!isNew ? (
                        <Grid item xs={12}>
                          <Item>
                            <div>
                              <TextField
                                style={{ width: "100%" }}
                                size="medium"
                                id="Comment"
                                label="Observation"
                                variant="standard"
                                error={CustomerComment?.error}
                                value={CustomerComment.value}
                                inputRef={inputRefComment}
                                onChange={handleChange}
                              />
                            </div>
                          </Item>
                        </Grid>
                      ) : null}
                    </>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      container
                      xs={12}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Item>
                        <div>
                          <Button variant="contained" onClick={saveCustomer}>
                            Save
                          </Button>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Modal>
            {/* endregion */}
          </div>
          <div>
            <Box>
              <Grid
                container
                spacing={2}
                boxShadow="0px 2px 0px 0px rgb(0 0 0 / 20%),0px 2px 0px 0px rgb(0 0 0 / 20%)"
                marginBottom="23px"
              >
                <Grid item xs={3}>
                  <Item style={{ background: "rgba(0,0,0,0%)" }}>
                    <div>
                      <p style={{ fontSize: 20 }}>
                        Total Active Clients <b>{countClients}</b>
                      </p>
                    </div>
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={9}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Item style={{ background: "rgba(0,0,0,0%)" }}>
                    <div>
                      <IconButton
                        color="primary"
                        component="label"
                        onClick={() => {
                          setIsNew(true);
                          setIsNewOrEdit(true);
                          setOpenClientCreate(true);
                          clearCustomer();
                        }}
                      >
                        <IoIcons.IoMdAddCircle />
                      </IconButton>
                    </div>
                    {/* <IconButton color="primary" component="label" onClick={() => {
                    setOpen(true)
                  }}>
                    <IoIcons.IoMdSearch/>
                  </IconButton>
                  <TextField id="searchCustormer" label="Search" variant="standard" /> */}
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </div>

          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
          />
        </div>
      </div>
    </>
  );
}

export default Customers;
