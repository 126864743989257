import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDIvMrPo6oHajC5r94squOeHAwLaC83Rdg",
    authDomain: "dynamos-82a6f.firebaseapp.com",
    projectId: "dynamos-82a6f",
    storageBucket: "dynamos-82a6f.appspot.com",
    messagingSenderId: "413550296812",
    appId: "1:413550296812:web:2663255f2b069da77f6fc9",
    measurementId: "G-NPXQVPKSPK"
};

export const app = initializeApp(firebaseConfig); 
export const auth = getAuth(app); 
export const firebaseSign = signInWithEmailAndPassword; 

// export const auth = getAuth(app);