import { Box, Modal } from "@mui/material";

//Table
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { format, parse } from "date-fns";
import { convertToTime } from "../utils/utils";

const modalStyles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 5,
    maxHeight: "100%",
    overflow: "scroll"
  },

  box: {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)"
  }
};

function createData(
  id: number,
  serviceDate: string,
  hasDivergence: string,
  acceptDivergence: string,
  workedHours: number,
  subTotal: number
) {
  return {
    id,
    serviceDate,
    hasDivergence,
    acceptDivergence,
    workedHours,
    subTotal
  };
}

const InvoiceModal = ({ invoice, open, onClose }) => {
  const handleClose = () => onClose?.();

  if (!invoice) return null;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyles.container}>
          <button
            onClick={handleClose}
            style={{
              position: "absolute",
              top: 5,
              right: 10,
              width: 25,
              height: 25,
              border: "none",
              background: "inherit"
            }}
          >
            X
          </button>
          <div style={modalStyles.box}>
            <p>Month: {parseInt(invoice.month)+ 1}</p>
            <p>Date: {format(new Date(invoice.date), "dd/MM/yyyy")}</p>
            <p>Total: {invoice.value}</p>
            <p>Type: {invoice.type}</p>
            <p>{invoice.id_employee ? "" : `Company: ${invoice.company}`}</p>
            {!!invoice.pendingAmount && (
              <p>
                {invoice.id_employee
                  ? `Amount to pay:  ${invoice.pendingAmount}`
                  : `Amount to charge: ${invoice.pendingAmount}`}
              </p>
            )}
          </div>
          <TableContainer className="container_table">
            <Table size="medium" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">
                    {invoice.id_employee ? "Customer" : "Employee"}
                  </TableCell>
                  <TableCell align="center">Payment in Cash </TableCell>
                  <TableCell align="center">Worked Hours</TableCell>
                  <TableCell align="center">Extra Cost</TableCell>
                  <TableCell align="center">SubTotal</TableCell>
                </TableRow>
                {invoice.items.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{row.id || "-"}</TableCell>
                    <TableCell align="center">
                      {format(
                        new Date(row.serviceSchedule?.date),
                        "dd/MM/yyyy"
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {invoice.id_employee
                        ? row.serviceSchedule.customer.name
                        : row.serviceSchedule.employee.name}
                    </TableCell>
                    <TableCell align="center">
                      {row.serviceSchedule.paymentInCash || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {convertToTime(row.serviceSchedule?.hoursExpended) + ((row.serviceSchedule?.hasDivergence && !!!row.serviceSchedule?.acceptDivergence) ? " (has divergence)" : "")}
                    </TableCell>
                    <TableCell align="center">
                      {row.serviceSchedule?.extraCost || "-"}
                    </TableCell>
                    <TableCell align="center">{row.subtotal}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
};

export default InvoiceModal;
