import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Logo from "../../components/icons/Logo";
import "./employeed.css";
import axios from "../../api/api";
import Modal from "../../modals/InvoiceViewModal";
import Dialog from "../../modals/dialogView";

//Table
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { format } from "date-fns";
import DataTable from "../../components/DataTable";
import { convertToTime } from "../../utils/utils";

function createDataPayment(
  id: number,
  nome: string,
  horasTrabalhadas: string,
  valorTrabalhado: number,
  valorRecebido: number,
  total: number,
  acoes: Array<string>
) {
  return {
    id,
    nome,
    horasTrabalhadas,
    valorTrabalhado,
    valorRecebido,
    total,
    acoes
  };
}

const PaymentEmployeed = () => {
  const [payments, setPayments] = useState([]);
  const [filtredPayments, setFiltredPayments] = useState([]);
  const [filtredHistory, setFiltredHistory] = useState([]);
  const [history, setHistory] = useState([]);
  const [viewInvoice, setViewInvoice] = useState(null);
  const [generateInvoice, setGenerateInvoice] = useState(null);
  const fetchList = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API + "/employee");
      setPayments(response.data);
      setFiltredPayments(response.data);
      setHistory(response.data.flatMap((d) => d.invoices));
      setFiltredHistory(response.data.flatMap((d) => d.invoices));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchPreview = async (row) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API + "/payment/employee/" + row.id
      );
      setViewInvoice({ ...response.data, pendingAmount: row.pendingAmount });
      // await fetchList();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const applyFilterPayments = (event) => {
    const value = event.target.value;
    const filtered = payments.filter((item) => {
      return (
        value == "-" ||
        item.paymentFrequency.toLowerCase() == (value.toLowerCase())
      );
    });
    setFiltredPayments(filtered);
  };

  const applyFilterHistory = (event) => {
    const value = event.target.value;
    const filtered = history.filter((item) => {
      return value == "0" || item.employee.id == value;
    });
    setFiltredHistory(filtered);
  };
  const fetchGenerate = async (id) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "/payment/employee/" + id
      );
      setGenerateInvoice(null);
      await fetchList();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchList();
  }, []);

  const expenseByCompany = (company) => {
    return (
      Number(payments
        ?.flatMap((a) => a.invoices)
        ?.flatMap((a) => a.items)
        ?.filter(
          (a) => a.serviceSchedule.customer.servicesFromCompany == company
        )
        ?.reduce((acc, a) => acc + a.subtotal, 0)).toFixed(2) || "-"
    );
  };
  return (
    <>
      <Navbar />
      <div className="payment_employeed">
        <div className="container_geral">
          <Logo />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <h2 className="total_debtors">
              Dynamos: £ {expenseByCompany("dynamos")}
            </h2>
            <h2 className="total_debtors">
              Clean: £ {expenseByCompany("clean")}
            </h2>
            <h2 className="total_debtors">
              Master: £ {expenseByCompany("master")}
            </h2>
            <h2 className="total_debtors">
              Bright: £ {expenseByCompany("bright")}
            </h2>
          </div>
          <hr />
          <div style={{ marginTop: 20 }} className="container_filtro">
            <p className="titulo_table">Amount Pedings Receipt :</p>
            <div>
              <label htmlFor="filtro">Frequency Filter: </label>
              <select onChange={applyFilterPayments} name="filtro" id="filtro">
                <option value="-">-</option>
                <option value="weekly"> weekly </option>
                <option value="biweekly">biweekly</option>
                <option value="monthly">month</option>
              </select>
            </div>
          </div>

          <DataTable
            cols={[
              { headerName: "Id" },
              { headerName: "Employee Name" },
              { headerName: "Worked Hous" },
              { headerName: "Amount Received" },
              { headerName: "Amount Pedings" },
              {
                headerName: "#",
                renderCell: (row) => (
                  <>
                    <button
                      onClick={() => {
                        setViewInvoice(null);
                        fetchPreview(filtredPayments[row.id]);
                      }}
                    >
                      Preview ✅
                    </button>{" "}
                    <button
                      onClick={() => {
                        setGenerateInvoice(filtredPayments[row.id]);
                      }}
                    >
                      Gerar 📋
                    </button>
                  </>
                )
              }
            ]}
            rows={filtredPayments.map((row) => {
              let workedHours = row.services
                ?.filter((a) => a.status == "completed")
                ?.reduce((acc, a) => acc + parseFloat(a.hoursExpended), 0);

              let amountReceived = row.services
                ?.filter((a) => a.status == "completed")
                ?.reduce((acc, a) => acc + parseFloat(a.paymentInCash || 0), 0);

              let pendingReceive =
                row.paymentPerHour *
                row.services
                  ?.filter((a) => a.status == "completed")
                  ?.reduce((acc, a) => acc + parseFloat(a.hoursExpended), 0) +
                row.services
                  ?.filter((a) => a.status == "completed")
                  ?.reduce((acc, a) => acc + parseFloat(a.extraCost || 0), 0);
              pendingReceive = pendingReceive - amountReceived;
              row.pendingAmount = pendingReceive;
              return [
                row.id,
                row.name,
                convertToTime(workedHours),
                parseFloat(amountReceived?.toFixed(2)),
                parseFloat(pendingReceive?.toFixed(2))
              ];
            })}
          />

          <div style={{ marginTop: 20 }} className="container_filtro">
            <p className="titulo_table">History:</p>
            <div>
              <label htmlFor="filtro">Employee Filter: </label>
              <select onChange={applyFilterHistory} name="filtro" id="filtro">
                <option value="0">-</option>
                {payments.map((employee) => {
                  return (
                    <option key={employee.id} value={employee.id}>
                      {employee.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <DataTable
            cols={[
              { headerName: "Id" },
              { headerName: "Employee Name" },
              { headerName: "Date" },
              { headerName: "Worked Hous" },
              { headerName: "Total" },
              {
                headerName: "#",
                renderCell: (row) => (
                  <>
                    <button
                      onClick={() => {
                        setViewInvoice(filtredHistory[row.id]);
                      }}
                    >
                      View 🔍
                    </button>
                  </>
                )
              }
            ]}
            rows={filtredHistory.map((row) => {
              return [
                row.id,
                row.employee.name,
                format(new Date(row?.date), "dd/MM/yyyy"),
                convertToTime(row.items.reduce(
                  (acc, a) => acc + parseFloat(a.serviceSchedule.hoursExpended),
                  0
                )),
                row.value
              ];
            })}
          />
        </div>
      </div>
      <Modal
        invoice={viewInvoice}
        open={viewInvoice != null}
        onClose={() => setViewInvoice(null)}
      />

      <Dialog
        open={generateInvoice != null}
        onClose={() => setGenerateInvoice(null)}
        onAccepted={() => fetchGenerate(generateInvoice.id)}
      />
    </>
  );
};

export default PaymentEmployeed;
