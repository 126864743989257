import { createContext, useEffect, useState } from "react";
import { firebaseSign, auth } from "../services/firebaseConfig";
import axios from "axios";

const login = async (email, password) => {
  try {
    const response = await axios.post(process.env.REACT_APP_API + "/auth", {
      login: email,
      password: password,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    const userToken = localStorage.getItem("user");
    if (userToken) {
      setUser(JSON.parse(userToken));
    }
  }, []);

  const signin = async (email, password) => {
    try {
      const user = await login(email, password);
      const token = user.token;

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      setUser(user);

      return user;
    } catch (err) {
      console.error(process.env.REACT_APP_API);
      throw "E-mail ou senha incorretos";
    }
  };

  // const signup = async (email, password) => {
  //   const usersStorage = JSON.parse(localStorage.getItem("users_bd"));

  //   const hasUser = usersStorage?.filter((user) => user.email === email);

  //   if (hasUser?.length) {
  //     return "Já tem uma conta com esse E-mail";
  //   }

  //   let newUser;

  //   if (usersStorage) {
  //     newUser = [...usersStorage, { email, password }];
  //   } else {
  //     newUser = [{ email, password }];
  //   }

  //   localStorage.setItem("users_bd", JSON.stringify(newUser));

  //   return;
  // };

  const signout = () => {
    setUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  };

  return (
    <AuthContext.Provider value={{ user, signed: !!user, signin, signout }}>
      {children}
    </AuthContext.Provider>
  );
};
