import react, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Logo from "../../components/icons/Logo";
import "./divergences.css";

import { TimeField } from "@mui/x-date-pickers";

//Table
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import axios from "../../api/api";
import Dialog from "../../modals/dialogView";
import { convertToDecimalHours, convertToTime } from "../../utils/utils";

// function createData(
//   id: number,
//   DataServico: string,
//   horasPrevistas: string,
//   horasTrabalhadas: string,
//   acoes:Array<string>
//   ){
//   return{ id,DataServico,horasPrevistas,horasTrabalhadas,acoes }
// }
// const rows = [
//   createData(1, '25/03/2023', '13 horas', '24 horas', [] ),
//   createData(2, '25/03/2023', '24 horas', '20 horas', ['✔️','❌']  ),
// ]

function Divergences() {
  const [divergences, setDivergences] = useState([]);
  const [editRow, setEditRow] = useState(null);
  const [acceptRow, setAcceptRow] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API + "/divergence"
      );
      setDivergences(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  async function handleUpdate(row) {
    try {
      const response = await axios.put(
        process.env.REACT_APP_API + `/divergence/${row.id}`,
        {
          acceptDivergence: row.acceptDivergence,
          hoursExpended: row.hoursExpended
        }
      );
      setAcceptRow(null);
      setEditRow(null);
      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  useEffect(() => {
    fetchData(); // Call the function to fetch data when the component mounts
  }, []);

  return (
    <>
      <Navbar />
      <div className="divergences">
        <div className="container_geral">
          <Logo />
          <TableContainer className="container_table">
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Costumer</TableCell>
                  <TableCell>Employee</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Expected Hours</TableCell>
                  <TableCell>Worked Hours</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {divergences.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.customer.name}</TableCell>
                    <TableCell>{row.employee.name}</TableCell>
                    <TableCell>{row.dateParsed}</TableCell>
                    <TableCell>{convertToTime(row.customer.servicesExpectedHours)}</TableCell>
                    <TableCell>{convertToTime(row.hoursExpended)}</TableCell>
                    <TableCell align="center">
                      <button onClick={() => setAcceptRow(row)}>✅</button>{" "}
                      <button onClick={() => setEditRow(row)}>📋</button>
                    </TableCell>
                  </TableRow>
                ))}
                <Dialog
                  open={acceptRow !== null}
                  onClose={() => setAcceptRow(null)}
                  onAccepted={() =>
                    handleUpdate({ ...acceptRow, acceptDivergence: true })
                  }
                />
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {editRow && (
          <div className="wrapper_modal_edit active">
            <div className="container_modal ">
              <button onClick={() => setEditRow(null)} className="close_modal">
                X
              </button>
              <div className="content_modal">
                <div>
                  <TimeField
                    label="Work hour(s)"
                    ampm={false}
                    //value={convertToTime(editRow.hoursExpended) || ""}
                    onChange={(c : any) =>{
                      try{
                        setEditRow({ ...editRow, hoursExpended: convertToDecimalHours(c?.format("hh:mm")) })
                      }catch(err){
                        console.log(err)
                      }
                    }}
                    name="horas"
                    id="horas"
                  />
                </div>
                <div className="form-group">
                  <button
                    className="btn_modal"
                    onClick={() =>
                      handleUpdate({
                        id: editRow.id,
                        acceptDivergence: true,
                        hoursExpended: editRow.hoursExpended
                      })
                    }
                  >
                    Enviar
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Divergences;
