import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


Date.prototype.toISOString = function() {
  // Extract the date components
  const year = this.getFullYear();
  const month = String(this.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(this.getDate()).padStart(2, '0');
  const hours = String(this.getHours()).padStart(2, '0');
  const minutes = String(this.getMinutes()).padStart(2, '0');
  const seconds = String(this.getSeconds()).padStart(2, '0');

  // Concatenate the components into a string
  const dateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

  return dateString;
};

Date.prototype.toJSON = function() {
  // Extract the date components
  const year = this.getFullYear();
  const month = String(this.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(this.getDate()).padStart(2, '0');
  const hours = String(this.getHours()).padStart(2, '0');
  const minutes = String(this.getMinutes()).padStart(2, '0');
  const seconds = String(this.getSeconds()).padStart(2, '0');

  // Concatenate the components into a string
  const dateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

  return dateString;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
