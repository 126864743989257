import React, { useEffect, useReducer } from 'react';
import { Grid, IconButton, Modal, Paper, styled } from "@mui/material"
import { Box } from "@mui/system"
import { Client } from '../interfaces/Cliente';
import { getClient } from '../models/clients';
import moment from 'moment';
import { getWeek } from '../models/week';
import * as AiIcons from 'react-icons/ai';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    border: theme.spacing(10),
    boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);",

}));
let style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    bgcolor: '#fff',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const initCustomer: Client = {
    id: 0,
    name: "",
    nameOfContact: "",
    email: "",
    phoneNumber: "",
    addressLine: "",
    paymentPerHour: 0,
    paymentFrequency: "",
    servicesFrequency: "",
    servicesExpectedHours: "",
    servicesByDayOfWeek: "",
    customerSince: new Date(),
    dateRefForSchedules: new Date(),
    paymentType: "",
    additionalInfo: "",
    servicesFromCompany: "",
    id_employee: 0,

};

const ViewCustomer = (props, ref) => {
    const [Customer, setCustomer] = React.useState<Client>(initCustomer);
    const [IdCustomer, SetIdCustomer] = React.useState<string>(props.customerId);
    const [weekView, setWeekView] = React.useState(getWeek());


    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);



    const getCustomer = async () => {
        const c: Client = await getClient(IdCustomer);
        var w = weekView;
        var days = c.servicesByDayOfWeek.split(',');
        days.forEach(element => {
            w.forEach(d => {
                if (element.trim() === d.dayName)
                    d.enabled = true
            });
        });
        setWeekView(w)
        setCustomer(c)
        forceUpdate()
        return c;

    }

    useEffect(() => {
        getCustomer()
    }, []);
    return (<>

        <Box sx={style}>
            <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ flexDirection: 'row-reverse', display: 'flex' }}>
                    <IconButton onClick={() => props.onClose?.()} >
                        <AiIcons.AiOutlineClose />
                    </IconButton>
                </Box>
                <Grid container spacing={2} boxShadow="0px 2px 0px 0px rgb(0 0 0 / 20%),0px 2px 0px 0px rgb(0 0 0 / 20%)" marginBottom="23px">

                    <Grid item xs={6}>
                        <Item>
                            <div>
                                <p><b>{Customer.name} </b>

                                </p>
                            </div>
                            <div>
                                <p><b>Contact Name </b>
                                    {Customer.nameOfContact}
                                </p>
                            </div>
                            <div>
                                <p><b>E-Mail </b>
                                    {Customer.email}
                                </p>
                            </div>
                            <div>
                                <p><b>Address </b>
                                    {Customer.addressLine}
                                </p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item>
                            <div>
                                <p><b>Phone Number </b>
                                    {Customer.phoneNumber}
                                </p>
                            </div>
                            <div>
                                <p><b>Prevision hours </b>
                                    {`${Customer.servicesExpectedHours} Hours/service`}
                                </p>
                            </div>
                            <div>
                                <p><b>Customer since </b>
                                    <>
                                        {moment(Customer.customerSince).format('DD/MM/YYYY')}
                                    </>
                                </p>
                            </div>
                            <div>
                            <p><b>Date Reference For Schedules</b>
                                    <>
                                        {moment(Customer.dateRefForSchedules).format('DD/MM/YYYY')}
                                    </>
                                </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>
                <Grid container spacing={2} >
                    <Grid item xs={6}>
                        <Item>
                            <div>
                                <p><b>Payment </b>
                                    {`${Customer.paymentPerHour} £/h`}
                                </p>
                            </div>
                            <div>
                                <p><b>Fix Employeed </b>
                                    {Customer.employeeName}
                                </p>
                            </div>
                            <div>
                                <p><b>Frequency </b>
                                    {Customer.servicesFrequency}
                                </p>
                            </div>

                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item>

                            <div>
                                <p><b>Payment method </b>
                                    {Customer.paymentType}
                                </p>
                            </div>
                            <div>
                                <p><b>Payday </b>
                                    {Customer.paymentFrequency}
                                </p>
                            </div>
                            <div>
                                <p><b>Services </b>
                                    {Customer.servicesFromCompany}
                                </p>
                            </div>

                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Item>
                            <div style={{ paddingBottom: 10 }}><b style={{ paddingRight: 20, paddingBottom: 10 }}>Days Week    </b></div>
                            <div>
                                {
                                    weekView.map((item, index) => {
                                        return (
                                            <div key={index} className='weekday' title={item.dayName} style={{ backgroundColor: !item.enabled ? 'white' : '#000340' }}>
                                                <div className='weekday2'>
                                                    <button style={{ backgroundColor: !item.enabled ? 'white' : '#000340', border: 0 }} >
                                                        <p style={{ fontSize: 30, color: item.enabled ? 'white' : 'black' }}>
                                                            <b>{item.day}</b>
                                                        </p>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </Item>
                    </Grid>
                </Grid>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Item>
                            <div>
                                <p><b>Observation </b>
                                    {Customer.additionalInfo}
                                </p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </Box >


    </>)
}

export default ViewCustomer