import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  height: 100vh;
  background: url("/bg.png") no-repeat center;
  background-size: cover;
`;

export const Content = styled.div`
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 1px 2px #0003;
  background-color: white;
  max-width: 350px;
  padding: 20px;
  border-radius: 5px;
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: 600;
  color: #676767;
`;

export const LabelSignup = styled.label`
  font-size: 16px;
  color: #676767;
`;

export const labelError = styled.label`
  font-size: 14px;
  color: red;
`;

export const Strong = styled.strong`
  cursor: pointer;

  a {
    text-decoration: none;
    color: #676767;
  }
`;

export const Button = styled.button`
  padding: 16px 20px;
  outline: none;
  border: 1px solid #060b26;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  background-color: inherit;
  color: #060b26;
  font-weight: 600;
  font-size: 16px;
  max-width: 350px;
  &:hover {
    background: #060b26;
    color: #fff;
    transition: 0.2s linear;
  }
`;
