import axios from "axios";
import moment from "moment";
import {
  CalendarEmployee,
  calendarData,
  AppointmentClient,
  CalendarAppointment,
  CheckListEmployee
} from "../interfaces/CheckList";
import { Client } from "../interfaces/Cliente";
import { EmployeeInterface } from "../interfaces/Employee";
import Employee from "../pages/admin/employee";
import { getClientAll, rowClient } from "./clients";
import { getEmployeeId, getEmployees } from "./employee";
import { getWeek, week } from "./week";
import { format, parse } from "date-fns";
export type rowScheduling = {
  id: number;
  Name: string;
  Monday: {
    clientId: number;
    nameClient: string;
  };
  Tuesday: string;
  Wednesday: string;
  Thursday: string;
  Friday: string;
  Saturday: string;
  Sunday: string;
};
const baseURL = process.env.REACT_APP_API;

export type rowsScheduling = rowScheduling[];

export const getAllScheduling = async () =>
  new Promise<CheckListEmployee[]>(async (resolve, reject) => {
    var rows: CheckListEmployee[] = [];
    await axios
      .get(baseURL + `/checklist/`)
      .then((response) => {
        var result: CheckListEmployee[] = [];

        response.data.forEach((element) => {
          var r: CheckListEmployee;
          let date = element.tp_date.split("-");
          try {
            r = {
              id: element.id_calendar,
              client: element.ct_name,
              date: format(
                new Date(
                  parseInt(date[0]),
                  parseInt(date[1]) - 1,
                  parseInt(date[2])
                ),
                "dd/MM/yyyy"
              ),
              checklist: element.status == "completed" ? "OK" : "-",
              completedAt:
                element.status == "completed"
                  ? moment(new Date(element.completedAt || new Date()))
                      .format("DD/MM/YYYY")
                      .toString()
                  : "-",
              address: element.ct_address,
              status: element.status
            };
            result.push(r);
          } catch (err) {
            console.log(err);
          }
        });

        resolve(result);
      })
      .catch((err) => {
        reject(rows);
      });
  });

export const saveScheduling = async (schs: CalendarAppointment[]) =>
  new Promise<boolean>(async (resolve, reject) => {
    axios
      .post(baseURL + `/calendar/save_many`, schs, {})
      .then(() => resolve(true))
      .catch(() => reject(false));
  });

export const updateAppointment = async (sch: CalendarAppointment) =>
  new Promise<boolean>(async (resolve, reject) => {
    axios
      .put(baseURL + `/checklist/${sch.id_calendar}`, sch, {})
      .then(() => resolve(true))
      .catch(() => reject(false));
  });
