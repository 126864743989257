import React from "react";
import * as AiIcons from "react-icons/ai";

export const SidebarData = [
  {
    title: "Home",
    path: "/checklist",
    icon: <AiIcons.AiTwotoneCheckCircle />,
    cName: "nav-text"
  }
];
