import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  Stack,
  styled,
  Switch,
  SwitchProps,
  TextField,
  ThemeProvider,
  Typography
} from "@mui/material";
import { DataGrid, GridColDef, GridComparatorFn } from "@mui/x-data-grid";
import React, { useEffect, useReducer, useState } from "react";
import Logo from "../../components/icons/Logo";
import Navbar from "../../components/Navbar";
import { getAllScheduling, updateAppointment } from "../../models/checkList";
import { formatMoney, maskDate } from "../../utils/utils";
import {
  AppointmentClient,
  CalendarAppointment,
  CheckListEmployee
} from "../../interfaces/CheckList";
import * as AiIcons from "react-icons/ai";
import { WeekTable } from "../../models/week";
import moment from "moment";
import { TimeField } from "@mui/x-date-pickers";
import * as MdIcons from "react-icons/md";
import { getAppointmentClient } from "../../models/calendar";
import Dialog from "../../modals/dialogView";
import { isAfter, isBefore } from "date-fns";
import axios from "../../api/api";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  border: theme.spacing(10),
  boxShadow:
    "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);"
}));

const initRows: CheckListEmployee[] = [];

function CheckList() {
  const [calendarStatus, setCalendarStatus] = useState(null);
  const fetchCalendarStatus = async () => {
    let response = await axios.get(
      process.env.REACT_APP_API + "/checklist/status"
    );
    if (response.data) {
      setCalendarStatus(response.data);
    }
  };
  useEffect(() => {
    fetchCalendarStatus();
  }, []);

  type inputProfecional = {
    name: string;
    id: string;
    error: boolean;
  };

  type inputText = {
    value: string;
    error: boolean;
  };

  var initWeekDays: WeekTable = {
    Friday: "",
    Monday: "",
    Tuesday: "",
    Wednesday: "",
    Thursday: "",
    Saturday: "",
    Sunday: ""
  };

  const initAppointmentClientView: AppointmentClient = {
    tp_date: new Date(),
    ct_name: "",
    t_dy_client_id_client: 0,
    id_calendar: 0,
    is_temp: "n",
    pr_name: "",
    pr_email: "",
    py_cash: 0,
    ex_cost: 0,
    hr_worked: "",
    vl_salary: 0,
    fr_hour: 0,
    dt_paymenttype: "",
    id_professional: 0,
    id_employee: 0,
    status: "pending"
  };
  type Severity = "error" | "success" | "info" | "warning" | undefined;

  const [rows, setRows] = React.useState<CheckListEmployee[]>(initRows);
  const [pendings, setPendings] = React.useState<CheckListEmployee[]>([]);
  const [completeds, setCompleteds] = React.useState<CheckListEmployee[]>([]);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [dateInitReference, setDateInitReference] =
    React.useState<Date | null>();
  const [isValid, setIsValid] = React.useState(false);
  const [typeMessage, setTypeMessage] = React.useState<Severity>("success");
  const [WeekTableDays, setWeekTableDays] =
    React.useState<WeekTable>(initWeekDays);
  const [AppointmentClientView, setAppointmentClientView] = React.useState(
    initAppointmentClientView
  );
  const [OpenAppointmentDialog, setOpenAppointmentDialog] =
    React.useState(false);

  const [openServiceView, setOpenServiceView] = React.useState(false);
  const handleCloseServiceView = () => {
    setAppointmentClientView(initAppointmentClientView);
    setworkHours({ value: "", error: false });
    setExtraCost({ value: "", error: false });
    setOpenServiceView(false);
    forceUpdate();
  };

  const [IdProfessional, setIdProfessional] = React.useState<inputProfecional>({
    name: "",
    id: "",
    error: false
  });
  const [IdClient, setIdClient] = React.useState<inputProfecional>({
    name: "",
    id: "",
    error: false
  });
  const [DateStart, setDateStart] = React.useState<inputText>({
    value: "",
    error: false
  });
  const [DateEnd, setDateEnd] = React.useState<inputText>({
    value: "",
    error: false
  });

  const [payInCash, setPayInCash] = React.useState({
    value: "0.00",
    error: false
  });
  const [extraCost, setExtraCost] = React.useState({
    value: "0.00",
    error: false
  });
  const [workHours, setworkHours] = React.useState({ value: "", error: false });

  const inputRefIdProfessional = React.useRef<HTMLInputElement>();
  const inputRefIdClient = React.useRef<HTMLInputElement>();
  const inputRefDateStart = React.useRef<HTMLInputElement>();
  const inputRefDateEnd = React.useRef<HTMLInputElement>();
  const inputRefDateReference = React.useRef<HTMLInputElement>();
  const inputRefDateEdit = React.useRef<HTMLInputElement>();

  const inputRefExtraCost = React.useRef<HTMLInputElement>();
  const inputRefPayInCash = React.useRef<HTMLInputElement>();
  const inputRefWorkHours = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    inputRefIdProfessional.current?.focus();
  }, [IdProfessional]);
  React.useEffect(() => {
    inputRefIdClient.current?.focus();
  }, [IdClient]);
  React.useEffect(() => {
    inputRefDateStart.current?.focus();
  }, [DateStart]);
  React.useEffect(() => {
    inputRefDateEnd.current?.focus();
  }, [DateEnd]);
  React.useEffect(() => {
    inputRefDateReference.current?.focus();
  }, [dateInitReference]);
  React.useEffect(() => {
    inputRefDateEdit.current?.focus();
  }, [AppointmentClientView]);
  React.useEffect(() => {
    inputRefPayInCash.current?.focus();
  }, [payInCash]);
  React.useEffect(() => {
    inputRefExtraCost.current?.focus();
  }, [extraCost]);
  React.useEffect(() => {
    inputRefWorkHours.current?.focus();
  }, [workHours]);

  const getAppointmentClientView = async (id: Number) => {
    var a: AppointmentClient = await getAppointmentClient(id);
    setExtraCost({
      value: a.ex_cost ? parseFloat(a.ex_cost.toString()).toFixed(2) : "0.00",
      error: false
    });
    setworkHours({ value: a.hr_worked, error: false });
    setAppointmentClientView(a);
    forceUpdate();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsValid(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    maxWidth: "100%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
  };
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      align: "center",
      headerAlign: "center"
    },
    { field: "client", headerName: "Client", width: 231 },
    {
      field: "date",
      headerName: "Date",
      width: 231,
      sortComparator: (param1, param2) => {
        const dateSplited1 = param1.split("/");
        const dateSplited2 = param2.split("/");
        return `${dateSplited1[2]}-${dateSplited1[1]}-${dateSplited1[0]}` >
          `${dateSplited2[2]}-${dateSplited2[1]}-${dateSplited2[0]}`
          ? -1
          : `${dateSplited2[2]}-${dateSplited2[1]}-${dateSplited2[0]}` >
            `${dateSplited1[2]}-${dateSplited1[1]}-${dateSplited1[0]}`
          ? 1
          : 0;
      }
    },
    {
      field: "checklist",
      headerName: "Checklist",
      width: 175,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "completedAt",
      headerName: "Sent At",
      width: 175,
      align: "center",
      headerAlign: "center"
    },
    { field: "address", headerName: "Address", width: 310 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 131,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => {
        const dateSplited = params.row.date.split("/");
        const canRespond =
          new Date() >=
          new Date(
            parseInt(dateSplited[2]),
            parseInt(dateSplited[1]) - 1,
            parseInt(dateSplited[0])
          );
        return (
          <>
            <div>
              {canRespond ? (
                <IconButton
                  color="primary"
                  component="label"
                  onClick={() => {
                    getAppointmentClientView(params.row.id);
                    setOpenServiceView(true);
                  }}
                >
                  <MdIcons.MdOutlineChecklist />
                </IconButton>
              ) : (
                <>Not Available</>
              )}
            </div>
          </>
        );
      }
    }
  ];
  const getScheduling = async () => {
    const r: CheckListEmployee[] = await getAllScheduling();

    setRows(r);
    setPendings(r.filter((a) => !a.status.includes("comple")));
    setCompleteds(r.filter((a) => a.status.includes("comple")));
    forceUpdate();
    return r;
  };

  useEffect(() => {
    initDateInitialReference();
  }, []);

  useEffect(() => {
    setWeekDays();
  }, [dateInitReference]);

  const setWeekDays = () => {
    var d = new Date();
    var datetext = `${dateInitReference?.getFullYear()}-${
      dateInitReference?.getMonth() + 1
    }-${dateInitReference?.getDate()}`;

    d = new Date(datetext);

    var w: WeekTable = initWeekDays;
    if (d !== undefined) {
      w.Monday = moment(d).format("DD/MM/YYYY").toString();
      d.setDate(d.getDate() + 1);
      w.Tuesday = moment(d).format("DD/MM/YYYY");
      d.setDate(d.getDate() + 1);
      w.Wednesday = moment(d).format("DD/MM/YYYY");
      d.setDate(d.getDate() + 1);
      w.Thursday = moment(d).format("DD/MM/YYYY");
      d.setDate(d.getDate() + 1);
      w.Friday = moment(d).format("DD/MM/YYYY");
      d.setDate(d.getDate() + 1);
      w.Saturday = moment(d).format("DD/MM/YYYY");
      d.setDate(d.getDate() + 1);
      w.Sunday = moment(d).format("DD/MM/YYYY");
      setWeekTableDays(w);
    }
  };

  const initDateInitialReference = async () => {
    var d = new Date();
    if (d.getDay() == 0) {
      d.setDate(d.getDate() + 1);
    } else {
      while (d.getDay() > 1) {
        d.setDate(d.getDate() - 1);
      }
    }
    setDateInitReference(d);
    getScheduling();

    //setWeekDays(d)
    forceUpdate();
  };

  const handleChange = (event) => {
    switch (event.target.id) {
      case "start": {
        var value: inputText = {
          value: maskDate(event.target.value, DateStart.value),
          error: false
        };
        setDateStart(value);

        break;
      }
      case "payCash": {
        var v = formatMoney(event.target.value, payInCash.value);
        var appon = AppointmentClientView;
        appon.py_cash = parseFloat(parseFloat(v).toFixed(2));
        var value: inputText = {
          value: v,
          error: event.target.value.length === 0
        };
        setAppointmentClientView(appon);
        setPayInCash(value);

        break;
      }
      case "extraCost": {
        var v = formatMoney(event.target.value, extraCost.value);
        var appon = AppointmentClientView;
        appon.ex_cost = parseFloat(parseFloat(v).toFixed(2));
        var value: inputText = {
          value: v,
          error: event.target.value.length === 0
        };
        setAppointmentClientView(appon);
        setExtraCost(value);

        break;
      }

      case "end": {
        var value: inputText = {
          value: maskDate(event.target.value, DateEnd.value),
          error: false
        };
        setDateEnd(value);

        break;
      }
      case "workHour": {
        var value: inputText = {
          value: event.target.value,
          error: event.target.value.length === 0
        };
        setworkHours(value);

        var appon = AppointmentClientView;
        appon.hr_worked = event.target.value;
        setAppointmentClientView(appon);

        //setExtraCost(v);

        break;
      }
    }
  };

  const validateFilds = () => {
    var isValid = true;

    if (extraCost.value === "") {
      setExtraCost((x) => {
        return { ...x, error: true };
      });
      isValid = false;
    }
    if (
      workHours.value === "0:00" ||
      workHours.value === "0:00" ||
      workHours.value === "00:00" ||
      workHours.value === "12:00" ||
      workHours.value === "" ||
      workHours.value === "--:--" ||
      workHours.value === null
    ) {
      setworkHours((x) => {
        return { ...x, error: true };
      });
      isValid = false;
    }
    if (payInCash.value == "") {
      setPayInCash((x) => {
        return { ...x, error: true };
      });
      isValid = false;
    }

    return isValid;
  };

  const saveAppointment = async () => {
    setOpenAppointmentDialog(false);
    var validFilds = validateFilds();
    if (validFilds) {
      var appo: CalendarAppointment = {
        id_calendar: AppointmentClientView.id_calendar,
        hr_worked: AppointmentClientView.hr_worked,
        py_cash: AppointmentClientView.py_cash,
        ex_cost: AppointmentClientView.ex_cost,
        id_ot_professional: AppointmentClientView.id_professional,
        tp_date: AppointmentClientView.tp_date,
        is_temp: AppointmentClientView.is_temp,
        t_dy_client_id_client: AppointmentClientView.t_dy_client_id_client,
        status: "completed"
      };
      var save = await updateAppointment(appo);
      var save = true;
      setIsValid(save);
      setMessageAlert("Save success!");
      setTypeMessage("success");
      handleCloseServiceView();
      getScheduling();
    } else {
      setIsValid(true);
      setMessageAlert("Fill in the fields correctly!");
      setTypeMessage("error");
    }
  };

  return (
    <>
      <Navbar />
      <div className="calendar">
        <div style={{ height: 700, width: 1150 }}>
          <Snackbar
            open={isValid}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert severity={typeMessage} sx={{ width: "100%" }}>
              {messageAlert}
            </Alert>
          </Snackbar>
          {/* region modal ViewClient */}
          <Modal
            open={openServiceView}
            onClose={handleCloseServiceView}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ flexDirection: "row-reverse", display: "flex" }}>
                  <IconButton onClick={handleCloseServiceView}>
                    <AiIcons.AiOutlineClose />
                  </IconButton>
                </Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {`Service`}
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Item style={{ verticalAlign: "center" }}>
                      <div>
                        <p>
                          <b>{AppointmentClientView.pr_name} </b>
                        </p>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <Box>
                        <p>
                          <b>
                            {AppointmentClientView.id_professional ===
                            AppointmentClientView.id_employee
                              ? "Current "
                              : "Temporary "}
                            {`Employee`}
                          </b>
                        </p>
                      </Box>
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Item style={{ verticalAlign: "center" }}>
                      <div>
                        <p>
                          <b>{`Cliente  `}</b>
                          {AppointmentClientView.ct_name}
                        </p>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item style={{ verticalAlign: "center" }}>
                      <div>
                        <p>
                          <b>{`Date  `}</b>
                          {moment(AppointmentClientView.tp_date)
                            .format("DD/MM/YYYY")
                            .toString()}
                        </p>
                      </div>
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Item style={{ verticalAlign: "center" }}>
                      <Box>
                        <p>
                          <b>E-mail </b> {AppointmentClientView.pr_email}
                        </p>
                      </Box>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item style={{ verticalAlign: "center" }}>
                      <Box>
                        <p>
                          <b>Status </b> {AppointmentClientView.status}
                        </p>
                      </Box>
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Item>
                      <Box>
                        <p>
                          <b>{`Prevision hours  `}</b>
                          {`${AppointmentClientView.fr_hour} hour(s)`}
                        </p>
                      </Box>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <Box>
                        <p>
                          <b>{`Total prevision  `}</b>
                          {`£ ${(
                            AppointmentClientView.vl_salary *
                            AppointmentClientView.fr_hour
                          ).toFixed(2)}`}
                        </p>
                      </Box>
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Item>
                      <Box>
                        <p>
                          <b>{`Payment method  `}</b>
                          {AppointmentClientView.dt_paymenttype
                            ? AppointmentClientView.dt_paymenttype[0].toUpperCase() +
                              AppointmentClientView.dt_paymenttype.substring(1)
                            : ""}
                        </p>
                      </Box>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      {/* <Box>
                        <p><b>{`Pay in cash  `}</b>{`£${AppointmentClientView.py_cash ? AppointmentClientView.py_cash : "0.00"}`}
                        </p>
                      </Box> */}
                      <div>
                        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                          <InputLabel htmlFor="standard-adornment-amount">
                            Pay in cash
                          </InputLabel>
                          <Input
                            id="payCash"
                            // defaultValue={""}
                            error={payInCash.error}
                            value={payInCash.value}
                            inputRef={inputRefPayInCash}
                            disabled={
                              AppointmentClientView.status == "completed" ||
                              AppointmentClientView.dt_paymenttype !== "cash"
                            }
                            onChange={handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                £
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Item>
                      {/* <Box >
                        <p><b>{`Extra Cost  `}</b>{`£${AppointmentClientView.ex_cost ? AppointmentClientView.ex_cost : "0.00"}`}
                        </p>
                      </Box> */}
                      <div>
                        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                          <InputLabel htmlFor="standard-adornment-amount">
                            Extra Cost
                          </InputLabel>
                          <Input
                            id="extraCost"
                            // defaultValue={""}
                            value={extraCost.value}
                            error={extraCost.error}
                            inputRef={inputRefExtraCost}
                            disabled={
                              AppointmentClientView.status == "completed"
                            }
                            onChange={handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                £
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      {AppointmentClientView.status === "completed" ? (
                        <Box>
                          <p>
                            <b>{`Work hour(s)  `}</b>
                            {` ${
                              AppointmentClientView.hr_worked
                                ? AppointmentClientView.hr_worked
                                : "0:00"
                            }`}
                          </p>
                        </Box>
                      ) : (
                        <div>
                          <TimeField
                            id="workHour"
                            label="Work hour(s)"
                            ampm={false}
                            disabled={
                              AppointmentClientView.status == "completed"
                            }
                            onChange={(c: any) =>
                              handleChange({
                                target: {
                                  id: "workHour",
                                  value: c?.format("hh:mm")
                                }
                              })
                            }
                          />
                        </div>
                      )}
                    </Item>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    container
                    xs={12}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Item>
                      <div>
                        <Button
                          variant="contained"
                          disabled={AppointmentClientView.status == "completed"}
                          onClick={() => {
                            setOpenAppointmentDialog(true);
                          }}
                        >
                          Save
                        </Button>
                      </div>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Modal>
          <Dialog
            open={OpenAppointmentDialog}
            onClose={() => setOpenAppointmentDialog(false)}
            onAccepted={() => saveAppointment()}
          />
          {/* endregion */}
          <Logo />
          <div>
            <Box>
              <Grid container spacing={2} marginBottom="23px">
                <Grid item xs={4}>
                  <Item style={{ background: "rgba(0,0,0,0%)" }}>
                    <div>
                      <p style={{ fontSize: 20 }}>
                        Appointments today <b>{calendarStatus?.today || "-"}</b>
                      </p>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Item style={{ background: "rgba(0,0,0,0%)" }}>
                    <div>
                      <p style={{ fontSize: 20 }}>
                        Appointments in the week{" "}
                        <b>{calendarStatus?.week || "-"}</b>
                      </p>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Item style={{ background: "rgba(0,0,0,0%)" }}>
                    <div>
                      <p style={{ fontSize: 20 }}>
                        Appointments in the month{" "}
                        <b>{calendarStatus?.month || "-"}</b>
                      </p>
                    </div>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </div>
          <p>Pendigs:</p>
          <DataGrid
            rowHeight={150}
            rows={pendings}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
          />
          <br />
          <p>Completed:</p>
          <DataGrid
            rowHeight={150}
            rows={completeds}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
          />
        </div>
      </div>
    </>
  );
}

export default CheckList;
