import axios from "../api/api";
import {
  CalendarEmployee,
  calendarData,
  AppointmentClient,
  CalendarAppointment
} from "../interfaces/CheckList";
import { Client } from "../interfaces/Cliente";
import { EmployeeInterface } from "../interfaces/Employee";
import Employee from "../pages/admin/employee";
import { formatDateIgnoringTimezone } from "../utils/utils";
import { getClientAll, rowClient } from "./clients";
import { getEmployeeId, getEmployees } from "./employee";
import { getWeek, week } from "./week";
export type rowScheduling = {
  id: number;
  Name: string;
  Monday: {
    clientId: number;
    nameClient: string;
  };
  Tuesday: string;
  Wednesday: string;
  Thursday: string;
  Friday: string;
  Saturday: string;
  Sunday: string;
};
const baseURL = process.env.REACT_APP_API;

export type rowsScheduling = rowScheduling[];

export const getAllScheduling = async (dateInit: Date) =>
  new Promise<CalendarEmployee[]>(async (resolve, reject) => {
    var dateReference = new Date();
    dateReference = dateInit;
    var rows: rowsScheduling = [];
    var date = `${dateReference.getFullYear()}-${dateReference.getMonth() + 1
      }-${dateReference.getDate()}`;
    await axios
      .get(baseURL + `/service/week?date=${date}`)
      .then((response) => {
        var result: CalendarEmployee[] = [];
        var objetosUnicos = response.data.filter(function (
          objeto,
          index,
          array
        ) {
          return (
            array.findIndex(function (obj) {
              return obj.id_professional === objeto.id_professional;
            }) === index
          );
        });
        var ids = objetosUnicos.map(function (objeto) {
          return { id: objeto.id_professional, name: objeto.pr_name };
        });
        ids.forEach((element) => {
          var emp: CalendarEmployee = {
            id: element.id,
            pr_name: element.name,
            Monday: new Array<calendarData>(),
            Friday: new Array<calendarData>(),
            Sunday: new Array<calendarData>(),
            Saturday: new Array<calendarData>(),
            Thursday: new Array<calendarData>(),
            Tuesday: new Array<calendarData>(),
            Wednesday: new Array<calendarData>()
          };
          var Monday: Array<calendarData> = [];
          var Friday: Array<calendarData> = [];
          var Sunday: Array<calendarData> = [];
          var Saturday: Array<calendarData> = [];
          var Thursday: Array<calendarData> = [];
          var Tuesday: Array<calendarData> = [];
          var Wednesday: Array<calendarData> = [];
          var calendar = new Array();
          response.data.forEach((r) => {
            if (element.id == r.id_professional) {
              let a = r.tp_date.split("-");
              switch (new Date(Date.UTC(parseInt(a[0]), parseInt(a[1]) - 1, parseInt(a[2]))).getUTCDay()) {
                case 1:
                  emp.Monday.push({
                    tp_date: r.tp_date,
                    ct_name: r.ct_name,
                    t_dy_client_id_client: r.t_dy_client_id_client,
                    id_calendar: r.id_calendar,
                    is_temp: r.is_temp,
                    id_employee: r.id_employee,
                    id_professional: r.id_professional
                  });
                  break;
                case 2:
                  emp.Tuesday.push({
                    tp_date: r.tp_date,
                    ct_name: r.ct_name,
                    t_dy_client_id_client: r.t_dy_client_id_client,
                    id_calendar: r.id_calendar,
                    is_temp: r.is_temp,
                    id_employee: r.id_employee,
                    id_professional: r.id_professional
                  });
                  break;

                case 3:
                  emp.Wednesday.push({
                    tp_date: r.tp_date,
                    ct_name: r.ct_name,
                    t_dy_client_id_client: r.t_dy_client_id_client,
                    id_calendar: r.id_calendar,
                    is_temp: r.is_temp,
                    id_employee: r.id_employee,
                    id_professional: r.id_professional
                  });
                  break;
                case 4:
                  emp.Thursday.push({
                    tp_date: r.tp_date,
                    ct_name: r.ct_name,
                    t_dy_client_id_client: r.t_dy_client_id_client,
                    id_calendar: r.id_calendar,
                    is_temp: r.is_temp,
                    id_employee: r.id_employee,
                    id_professional: r.id_professional
                  });
                  break;
                case 5:
                  emp.Friday.push({
                    tp_date: r.tp_date,
                    ct_name: r.ct_name,
                    t_dy_client_id_client: r.t_dy_client_id_client,
                    id_calendar: r.id_calendar,
                    is_temp: r.is_temp,
                    id_employee: r.id_employee,
                    id_professional: r.id_professional
                  });
                  break;
                case 6:
                  emp.Saturday.push({
                    tp_date: r.tp_date,
                    ct_name: r.ct_name,
                    t_dy_client_id_client: r.t_dy_client_id_client,
                    id_calendar: r.id_calendar,
                    is_temp: r.is_temp,
                    id_professional: r.id_professional,
                    id_employee: r.id_employee
                  });

                  break;
                case 0:
                  emp.Sunday.push({
                    tp_date: r.tp_date,
                    ct_name: r.ct_name,
                    t_dy_client_id_client: r.t_dy_client_id_client,
                    id_calendar: r.id_calendar,
                    is_temp: r.is_temp,
                    id_employee: r.id_employee,
                    id_professional: r.id_professional
                  });
                  break;
              }
            }
          });
          result.push(emp);
        });

        resolve(result);
      })
      .catch((err) => {
        reject([]);
      });
  });

export const getAppointmentClient = async (id: Number) =>
  new Promise<AppointmentClient>(async (resolve, reject) => {
    var appontment: AppointmentClient;
    await axios
      .get(baseURL + `/service/${id}`)
      .then((response) => {
        let a = response.data.tp_date.split("-");
        appontment = {
          tp_date: new Date(parseInt(a[0]), parseInt(a[1]) - 1, parseInt(a[2])),
          ct_name: response.data.ct_name,
          t_dy_client_id_client: response.data.t_dy_client_id_client,
          id_calendar: response.data.id_calendar,
          is_temp: response.data.is_temp,
          pr_name: response.data.pr_name,
          pr_email: response.data.pr_email,
          py_cash: response.data.py_cash,
          ex_cost: response.data.ex_cost,
          hr_worked: response.data.hr_worked,
          vl_salary: response.data.vl_salary,
          fr_hour: response.data.fr_hour,
          dt_paymenttype: response.data.dt_paymenttype,
          id_professional: response.data.id_professional,
          id_employee: response.data.id_employee,
          status: response.data.status
        };
        resolve(appontment);
      })
      .catch((err) => {
        reject(appontment);
      });
  });

export const gerateScheduling = async () =>
  new Promise<boolean>(async (resolve, reject) => {
    var currentDate = new Date();

    var dateReference = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1
      }-${currentDate.getDate()}`;
    try {
      await axios.post(baseURL + `/service/month?date=${dateReference}`);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });

export const addApontments = async (
  clientId: number,
  profecionalId: number,
  w: week,
  datestart: Date,
  dateEnd: Date
) =>
  new Promise<boolean>(async (resolve, reject) => {
    var newCheckList: CalendarAppointment[] = [];
    while (datestart <= dateEnd) {
      w.forEach((element) => {
        if (datestart.getDay() == element.dayId && element.enabled) {
          let ckList: CalendarAppointment = {
            t_dy_client_id_client: clientId,
            is_temp: "s",
            tp_date: new Date(datestart),
            py_cash: 0,
            ex_cost: 0,
            hr_worked: null,
            id_ot_professional: profecionalId
          };
          newCheckList.push(ckList);
        }
      });
      const d = datestart.toLocaleDateString();
      datestart.setDate(datestart.getDate() + 1);

      // "01/08/2022"
    }

    axios
      .post(baseURL + `/service/`, newCheckList, {})
      .then(() => resolve(true))
      .catch(() => reject(false));
  });

export const updateAppointment = async (sch: CalendarAppointment) =>
  new Promise<boolean>(async (resolve, reject) => {
    var data = {...sch} as any;
    data.tp_date = formatDateIgnoringTimezone(data.tp_date);
    axios
      .put(baseURL + `/service/${sch.id_calendar}`, data, {})
      .then(() => resolve(true))
      .catch(() => reject(false));
  });

export const deleteAppointment = async (id: string) =>
  new Promise<boolean>(async (resolve, reject) => {
    axios
      .delete(baseURL + `/service/${id}`, {})
      .then(() => resolve(true))
      .catch(() => reject(false));
  });
