import { Client } from "../interfaces/Cliente";
import axios from "../api/api";
import React from "react";

const baseURL = process.env.REACT_APP_API + "/customer";

export const getClients = async () =>
  new Promise<rowsClient>(async (resolve, reject) => {
    var rows: rowsClient = [];

    axios
      .get(baseURL)
      .then((response) => {
        response.data.forEach((element) => {
          var s = "";
          switch (element.servicesFromCompany) {
            case "dynamos":
              s = "Dynamos Cleaning Services Ltd";
              break;
            case "master":
              s = "Master Plus Cleaning Services Ltd";
              break;
            case "clean":
              s = "Clean Plus Cleaning Services Ltd";
              break;
            case "bright":
              s = "Bright Touch Cleaning Services Ltd";
              break;
          }
          var row: rowClient = {
            ...element,
            id: element.id,
            address: element.addressLine,
            phone: element.phoneNumber,
            company: element.name,
            services: s,
          };
          rows.push(row);
        });

        resolve(rows);
      })
      .catch((err) => {
        reject(rows);
      });
  });

export const getClientAll = async () =>
  new Promise<Client[]>(async (resolve, reject) => {
    var rows: Client[];

    axios
      .get(baseURL)
      .then((response) => {
        var rows: Client[] = [];
        response.data.forEach((element) => {
          // var c: Client = {
          //   id: element.id_client,
          //   name: element.ct_name,
          //   nameOfContact: element.ps_contact,
          //   email: element.ds_email,
          //   phoneNumber: element.nb_phone,
          //   addressLine: element.ct_address,
          //   paymentPerHour: Number(element.vl_hour),
          //   paymentFrequency: element.pd_frequency,
          //   servicesFrequency: element.fr_days,
          //   servicesExpectedHours: element.fr_hour,
          //   servicesByDayOfWeek: element.dy_frequency,
          //   customerSince: element.cr_since,
          //   paymentType: element.dt_paymenttype,
          //   additionalInfo: element.additionalInfo,
          //   servicesFromCompany: element.cl_services,
          //   employeeName:element.employeeName,
          //   id_employee: element.id_employee,
          // }
          rows.push(element as Client);
        });

        resolve(rows);
      })
      .catch((err) => {
        reject(rows);
      });
  });

export const getClient = async (id: string) =>
  new Promise<Client>(async (resolve, reject) => {
    var client: Client;
    axios
      .get(baseURL + `/${id}`)
      .then((response) => {
        client = response.data as Client;

        resolve(client);
      })
      .catch((err) => {
        reject(client);
      });
  });

export const saveClient = async (cli: Client) =>
  new Promise<boolean>(async (resolve, reject) => {
    axios
      .post(baseURL, cli, {})
      .then(() => resolve(true))
      .catch(() => reject(false));
  });

export const updateClient = async (cli: Client) =>
  new Promise<boolean>(async (resolve, reject) => {
    
    axios
      .put(baseURL + `/${cli.id}`, cli, {})
      .then(() => resolve(true))
      .catch(() => reject(false));
  });

export const deleteClient = async (id: string) =>
  new Promise<boolean>(async (resolve, reject) => {
    axios
      .delete(baseURL + `/${id}`, {})
      .then(() => resolve(true))
      .catch(() => reject(false));
  });
export type rowClient = {
  id: number;
  company: string;
  address: string;
  services: string;
  phone: string;
  status: string;
};

export type rowsClient = rowClient[];
export function getClients2() {
  var r: rowsClient = [];
  r = [
    {
      id: 1,
      company: "IT Trevo",
      address: "Vie de Ca...",
      services: "Dynamos",
      phone: "+55 (35) 921213213",
      status: "Debit"
    },
    {
      id: 2,
      company: "IT Trevo",
      address: "Vie de Ca...",
      services: "Dynamos",
      phone: "+55 (35) 921213213",
      status: "OK"
    },
    {
      id: 3,
      company: "IT Trevo",
      address: "Vie de Ca...",
      services: "Dynamos",
      phone: "+55 (35) 921213213",
      status: "Debit"
    },
    {
      id: 4,
      company: "IT Trevo",
      address: "Vie de Ca...",
      services: "Dynamos",
      phone: "+55 (35) 921213213",
      status: "OK"
    },
    {
      id: 5,
      company: "IT Trevo",
      address: "Vie de Ca...",
      services: "Dynamos",
      phone: "+55 (35) 921213213",
      status: "Debit"
    },
    {
      id: 6,
      company: "IT Trevo",
      address: "Vie de Ca...",
      services: "Dynamos",
      phone: "+55 (35) 921213213",
      status: "OK"
    }
  ];
  return r;
}
