import { parse } from "date-fns";

//formato BR
// export const formatPhone = (phoneNew: string, phoneOld: string) => {
//     var tel = phoneNew;
//     tel = tel.replace(/\D/g, "")
//     tel = tel.replace(/^(\d)/, "+$1")
//     tel = tel.replace(/(.{3})(\d)/, "$1($2")
//     tel = tel.replace(/(.{6})(\d)/, "$1)$2")
//     if (tel.length == 12) {
//         tel = tel.replace(/(.{1})$/, "-$1")
//     } else if (tel.length == 13) {
//         tel = tel.replace(/(.{2})$/, "-$1")
//     } else if (tel.length == 14) {
//         tel = tel.replace(/(.{3})$/, "-$1")
//     } else if (tel.length == 15) {
//         tel = tel.replace(/(.{4})$/, "-$1")
//     } else if (tel.length > 15) {
//         tel = tel.replace(/(.{4})$/, "-$1")
//     }
//     if (tel.length <= 17)
//         return tel;
//     else return phoneOld;

// }

///formato londres
 export const formatPhone = (phoneNew: string, phoneOld: string) => {
     var tel = phoneNew;
     tel = tel.replace(/\D/g, "");
     tel = tel.replace(/^(\d)/, "$1");
    
     if (tel.length === 12) {
         tel = tel.replace(/(\d{2})(\d{4})(\d{4})/, "$1 $2 $3");
     } else if (tel.length === 13) {
         tel = tel.replace(/(\d{3})(\d{4})(\d{4})/, "$1 $2 $3");
     } else if (tel.length === 14) {
         tel = tel.replace(/(\d{4})(\d{4})(\d{4})/, "$1 $2 $3");
     } else if (tel.length === 15) {
         tel = tel.replace(/(\d{5})(\d{4})(\d{4})/, "$1 $2 $3");
     } else if (tel.length > 15) {
         tel = tel.replace(/(\d{5})(\d{4})(\d{4})/, "$1 $2 $3");
     }
    
     if (tel.length <= 17) {
         return "+" + tel;
     } else {
         return phoneOld;
     }
 };


export const formatMoney = (moneyNew: string, moneyOld: string) => {
    if (typeof moneyNew === "undefined" || moneyNew == null)
        return "0";

    let valor = '';

    if (typeof moneyNew !== "undefined") {
        valor = moneyNew;
    } else {
        valor = moneyNew;
    }

    if (valor.length == 2)
        return valor;

    let v = valor;
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1,2})$/, '.$1');
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return v;

}

export const maskDate = (dateNew: string, dateold: string) => {
    var value = dateNew
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1")
    if (value.length <= 10)
        return value;
}
export const validateEmail = (email: string) => {

    var regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return regex.test(email);
}

export const validateMoney = (money: string) => {
    var regex = /^\d{1,3}(,\d{3})*\.\d{2}$/;
    return regex.test(money);
}

export const validateData = (data: string) => {
    // Attempt to parse the input data as a date
  const parsedDate = parse(data, 'dd/MM/yyyy', new Date());

  // If parsedDate is null, the input data is not a valid date
  if (parsedDate === null || isNaN(parsedDate.getTime())) {
    return false;
  }

  return true;
}



export function convertToDecimalHours(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    const decimalHours = hours + minutes / 60;
    return decimalHours;
  }
  
  export function convertToTime(decimalHours) {
    if (decimalHours && decimalHours > 0) {
      const hours = Math.floor(decimalHours);
      const minutes = Math.round((decimalHours - hours) * 60);
      const hoursString = hours.toString().padStart(2, "0");
      const minutesString = minutes.toString().padStart(2, "0");
      return `${hoursString}:${minutesString}`;
    } else {
      return "00:00";
    }
  }

  export function formatDateIgnoringTimezone(date) {
    // Extract the date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Concatenate the components into a string
    const dateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

    return dateString;
}

export type Severity = "error" | "success" | "info" | "warning" | undefined;

export const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
