import { Box, Modal } from "@mui/material";

const modalStyles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 5,
    maxHeight: "100%",
    overflow: "scroll"
  },

  buttonAccept: {
    background: "#32a852",
    color: "#fff",
    padding: 10,
    border: "none",
    marginInlineEnd: 10
  },

  buttonDecline: {
    background: "#cf2727",
    color: "#fff",
    padding: 10,
    border: "none",
    marginInlineEnd: 10
  },

  gridContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30
  }
};

const DialogView = ({ open, onClose, onAccepted }) => {
  const handleClose = () => onClose?.();
  const handleAccept = () => onAccepted?.();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyles.container}>
        <button
          onClick={handleClose}
          style={{
            position: "absolute",
            top: 5,
            right: 10,
            width: 25,
            height: 25,
            border: "none",
            background: "inherit"
          }}
        >
          X
        </button>
        <h2>Are you sure you want to perform this operation?</h2>
        <div style={modalStyles.gridContainer}>
          <input
            onClick={handleAccept}
            style={modalStyles.buttonAccept}
            type="button"
            value="Accept"
          />
          <input
            onClick={handleClose}
            style={modalStyles.buttonDecline}
            type="button"
            value="Decline"
          />
        </div>
      </Box>
    </Modal>
  );
};

export default DialogView;
