import {Box} from '@mui/material';
import logoSrc from "../../images/logo.PNG"
const Logo = (props) => {
  return (

    <Box
    component="img"
    sx={{
      height: 100,
      width: 100,
      maxHeight: { xs: 100, md: 100 },
      maxWidth: { xs: 100, md: 100 },
    }}
    alt="The house from the offer."
    src={logoSrc}
  />
  )
}
export default Logo;