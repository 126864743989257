import React, { useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp
} from "@mui/x-data-grid";

interface CustomHeader {
  headerName: string;
  renderCell?: (row: any) => React.ReactNode;
}

interface DataTableProps {
  cols: Array<CustomHeader>;
  rows: Array<any>;
}
function DataTable(props: DataTableProps) {
  return (
    <DataGrid
      columns={props.cols?.map((c, index) => {
        let muixCol: GridColDef = { ...c, field: index.toString() };
        muixCol.sortable = true;
        muixCol.flex = 1 / props.cols.length;
        muixCol.renderCell =
          c.renderCell ||
          function (row: GridRenderCellParams<any>) {
            return row.value;
          };
        return muixCol;
      })}
      rows={props.rows?.map((r, index) => {
        let muixRow: any = {};
        muixRow.id = index.toString();
        Object.assign(muixRow, r);
        return muixRow;
      })}
    ></DataGrid>
  );
}

export default DataTable;
