import axios from "../api/api";
import { EmployeeInterface } from "../interfaces/Employee";

export type rowEmployee = {
  id: number,
  Name: string,
  Salary: Number,
  Payday: string,
  Phone: string,
  AdcPhone: string,
  Email: string,
}

export type rowClient = {
  id: number,
  name: string,
  hours: string,
  frequency: string
}
export type rowsClient = rowClient[];
export type rowsEmployee = rowEmployee[];


const baseURL = process.env.REACT_APP_API + "/employee";

export const saveEmployee = async (emp: EmployeeInterface) => new Promise<boolean>(async (resolve, reject) => {

  axios.post(baseURL, emp, {

  }).then(() => resolve(true)).catch(() => reject(false));
})

export const updateEmployee = async (emp: EmployeeInterface) => new Promise<boolean>(async (resolve, reject) => {

  axios.put(baseURL + `/${emp.id}`, emp, {

  }).then(() => resolve(true)).catch(() => reject(false));
})

export const deleteEmployee = async (id: string) => new Promise<boolean>(async (resolve, reject) => {

  axios.delete(baseURL + `/${id}`, {

  }).then(() => resolve(true)).catch(() => reject(false));
})

export const getAllEmployees = async () => new Promise<rowsEmployee>(async (resolve, reject) => {
  var rows: rowsEmployee = [];

  axios.get(baseURL).then((response) => {
    response.data.forEach(element => {
      var row: rowEmployee = {
        ...element,
        id: element.id,
        Name: element.name,
        Phone: element.phoneNumber,
        AdcPhone: element.phoneNumber2,
        Salary: element.paymentPerHour,
        Payday: element.paymentFrequency,
        Email: element.email,
      }

      rows.push(row)
    });


    resolve(rows)
  }).catch((err) => {
    console.log("err:", err);
    reject(rows);
  });
})

export const getEmployeeId = async (id: string) => new Promise<EmployeeInterface>(async (resolve, reject) => {

  var client: EmployeeInterface;
  axios.get(baseURL + `/${id}`).then((response) => {

    client = response.data as EmployeeInterface;
    resolve(client)
  }).catch((err) => {
    console.log("err:", err);
    reject(client);
  });
})

export const getEmployees = async () => new Promise<EmployeeInterface[]>(async (resolve, reject) => {

  var employees: EmployeeInterface[];
  axios.get(baseURL).then((response) => {
    var employees: EmployeeInterface[] = [];
    response.data.forEach(element => {
      var e: EmployeeInterface = {
        id: element.id,
        name: element.name,
        birthDate: element.birthDate,
        email: element.email,
        addressLine: element.addressLine,
        hireDate: element.hireDate,
        phoneNumber: element.phoneNumber,
        phoneNumber2: element.phoneNumber2,
        natInsurance: element.natInsurance,
        availableServiceTypes: element.availableServiceTypes,
        paymentFrequency: element.paymentFrequency,
        paymentPerHour: element.paymentPerHour,
        accountNumber: element.accountNumber,
        layoffDate: element.layoffDate,
      }
      employees.push(e);
    });
    resolve(employees)
  }).catch((err) => {
    reject(employees);
  });
})

export const getClients = async (id: string) => new Promise<rowsClient>(async (resolve, reject) => {
  var rows: rowsClient = [];

  axios.get(baseURL + `/${id}/customers`).then((response) => {

    response.data.forEach(element => {
      var row: rowClient = {
        id: element.id,
        name: element.name,
        hours: element.paymentPerHour,
        frequency: element.servicesByDayOfWeek,
      }
      rows.push(row)
    });


    resolve(rows)
  }).catch((err) => {
    console.log("err:", err);
    reject(rows);
  });
})
export function getEmployees2() {
  return [
    {
      id: 1,
      company: 'IT Trevo',
      address: 'Vie de Ca...',
      services: 'Dynamos',
      phone: '+55 (35) 921213213',
      status: 'Debit'

    },
    {
      id: 2,
      company: 'IT Trevo',
      address: 'Vie de Ca...',
      services: 'Dynamos',
      phone: '+55 (35) 921213213',
      status: 'OK'
      ,
    },
    {
      id: 3,
      company: 'IT Trevo',
      address: 'Vie de Ca...',
      services: 'Dynamos',
      phone: '+55 (35) 921213213',
      status: 'Debit'

    },
    {
      id: 4,
      company: 'IT Trevo',
      address: 'Vie de Ca...',
      services: 'Dynamos',
      phone: '+55 (35) 921213213',
      status: 'OK'
      ,
    },
    {
      id: 5,
      company: 'IT Trevo',
      address: 'Vie de Ca...',
      services: 'Dynamos',
      phone: '+55 (35) 921213213',
      status: 'Debit'

    },
    {
      id: 6,
      company: 'IT Trevo',
      address: 'Vie de Ca...',
      services: 'Dynamos',
      phone: '+55 (35) 921213213',
      status: 'OK'

    },
  ];
}

