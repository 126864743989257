import "./App.css";
import { AuthProvider } from "./contexts/auth";
import RoutesApp from "./routes";
import GlobalStyle from "./styles/global";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const App = () => {
  document.title = "Clean Plus Services";
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"engb"}>
        <AuthProvider>
          <RoutesApp />
          <GlobalStyle />
        </AuthProvider>
      </LocalizationProvider>
    </>
  );
};

export default App;
