export type day = {
  dayId: number,
  day: string,
  enabled: boolean,
  dayName: string

}

export type week = day[];


export function getWeek() {

  var w: week = []


  for (let index = 0; index <= 6; index++) {
    var d: day = {
      dayId: 0,
      day: '',
      dayName: '',
      enabled: false
    }
    d.dayId = index;
    switch (index) {
      case 1:
        d.day = "M";
        d.dayName = "Monday";
        break;
      case 2:
        d.day = "T";
        d.dayName = "Tuesday";
        break;
      case 3:
        d.day = "W";
        d.dayName = "Wednesday";
        break;
      case 4:
        d.day = "T";
        d.dayName = "Thursday";
        break;
      case 5:
        d.day = "F";
        d.dayName = "Friday";
        break;
      case 6:
        d.day = "S";
        d.dayName = "Saturday";
        break;
      case 0:
        d.day = "S";
        d.dayName = "Sunday";
        break;
    }
    w.push(d);
  }
  return w;
}

export type WeekTable = {  
  Monday: string,
  Tuesday: string,
  Wednesday: string,
  Thursday: string,
  Friday: string,
  Saturday: string,
  Sunday: string,
}
